import ButtonApiCall from 'components/ButtonApiCall';
import { useAuth } from 'contexts/auth';
import { useCallback, useEffect, useState } from 'react';
import { sendApiRequest } from 'api/general';
import { handleErrorResponse } from 'utils/general';
import { getDynamicTableRows } from 'api/sections';
import { getAttributes } from 'api/collection_definitions';
import { getDynamicData } from 'utils/sections';
import { createLanguageString, getLocales } from 'api/locales';

const API_KEY = 'AIzaSyC0Lu8QynU9Jhrug28l0-XphN58UFgDHIw';
const GOOGLE_TRANSLATE_API = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
const LANGUAGES_DYNAMIC_TABLE_ID = '73c6d4a4-4014-4df9-9f9a-f82902418eef';

const NewGoogleTranslateButtonSection = ({
  ...props
}) => {
  const { setAuth } = useAuth();
  const [languages, setLanguages] = useState({});
  const [languageIds, setLanguageIds] = useState([]);

  const getLanguageAttributes = useCallback(() => {
    const param = {
      collection_definition: LANGUAGES_DYNAMIC_TABLE_ID,
    };
    return getAttributes(param)
      .then(({ data }) => {
        return data;
      }).catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const getLanguages = useCallback(() => {
    return getLanguageAttributes()
      .then((attributes) => {
        return getDynamicTableRows(LANGUAGES_DYNAMIC_TABLE_ID)
          .then(({ data }) => {
            const ids = data.map((language) => language.id);
            setLanguageIds(ids);
            const languagesToSort = data.map((language) => {
              return { ...getDynamicData(language, attributes), id: language.id };
            });
            const sortedPriorityLanguages = languagesToSort.sort((a, b) => a.priority - b.priority);
            const languageLabelMap = sortedPriorityLanguages.reduce((result, language) => {
              const { id, google_label } = language;
              const map = {};
              map[id] = google_label;
              return { ...result, ...map };
            }, []);
            setLanguages(languageLabelMap);
          }).catch((err) => {
            handleErrorResponse(err, setAuth);
          });
      }).catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [getLanguageAttributes, setAuth]);

  useEffect(() => {
    getLanguages();
  }, [getLanguages]);

  const sendTranslateApiRequest = useCallback((text, sourceLanguage, targetGoogleLanguange, sourceStringId) => {
    if (!text || !targetGoogleLanguange) {
      return Promise.resolve();
    }
    const params = {
      q: text,
      target: targetGoogleLanguange,
      source: sourceLanguage || 'en',
    };

    return sendApiRequest(GOOGLE_TRANSLATE_API, 'post', params, null)
      .then(({ data }) => {
        const { translatedText } = data.data.translations[0];
        return {
          language: targetGoogleLanguange,
          text: translatedText,
          source: sourceStringId,
        };
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const callApi = useCallback(() => {
    const params = {
      $expand: 'language_strings',
    }
    return getLocales(params).then(({ data: strings }) => {
      const stringsToTranslate = [];
      strings.forEach((string) => {
        const languageStrings = string.language_strings;
        const sourceStringMap = {};
        languageIds.forEach((languageId) => {
          let isLanguageTranslated = false;
          let currentStringId;
          languageStrings.forEach((languageString) => {
            currentStringId = languageString.string;
            sourceStringMap[currentStringId] = languageString;
            if (languageString.language_id === languageId) {
              isLanguageTranslated = true;
            }
          });
          if (!isLanguageTranslated) {
            stringsToTranslate.push({ ...sourceStringMap[currentStringId], target_language: languageId });
          }
        });
      });
      Promise.all(stringsToTranslate.map((string) => {
        const sourceText = string.translated_string;
        const sourceLanguage = string.language_id;
        const sourceGoogleLabel = languages[sourceLanguage];
        const targetLanguage = string.target_language;
        const targetGoogleLabel = languages[targetLanguage];
        const sourceStringId = string.string;
        return sendTranslateApiRequest(sourceText, sourceGoogleLabel, targetGoogleLabel, sourceStringId);
      })).then((translationPromises) => {
        Promise.all(translationPromises.map((promise) => {
          if (promise) {
            const languageId = Object.keys(languages).find((key) => languages[key] === promise.language);
            let translateString = promise.text;
            if (promise.language === 'en') {
              const splitStr = translateString.toLowerCase().split(' ');
              const formattedStr = splitStr.map((str) => str.charAt(0).toUpperCase() + str.substring(1));
              translateString = formattedStr.join(' ');
            }
            const body = {
              language_id: languageId,
              string: promise.source,
              translated_string: promise.text,
            };
            return createLanguageString(body);
          }
          return true;
        })).then(() => {
          alert('Translated');
        });
      });
    });
  }, [languageIds, languages, sendTranslateApiRequest]);

  return (
    <ButtonApiCall
      onClick={callApi}
      {...props}
    >
      New Translate
    </ButtonApiCall>
  );
};

NewGoogleTranslateButtonSection.propTypes = {};

export default NewGoogleTranslateButtonSection;
