import api from 'api';

export const getSections = (params) => {
  return api.get('/sections', params, 'bearer');
};

export const getSection = (id, params) => {
  return api.get(`/sections/${id}`, params, 'bearer');
};

export const updateSection = (id, body) => {
  return api.put(`/sections/${id}`, body, 'bearer');
};

export const createSection = (body) => {
  return api.post('/sections', body, 'bearer');
};

export const deleteSection = (id) => {
  return api.delete(`/sections/${id}`, 'bearer');
};

export const getPublicSections = (params) => {
  return api.get('/public/sections', params);
};

export const getPublicSection = (id, params) => {
  return api.get(`/public/sections/${id}`, params);
};

export const getSectionDefinitions = (params) => {
  return api.get('/section_definitions', params, 'bearer');
};

export const getSectionDefinition = (id, params) => {
  return api.get(`/section_definitions/${id}`, params, 'bearer');
};

export const getDynamicTableRows = (collectionDefinitionId, params) => {
  return api.get(`/dyn_t/${collectionDefinitionId}`, params, 'bearer');
};

export const getDynamicTableRow = (collectionDefinitionId, id, params) => {
  return api.get(`/dyn_t/${collectionDefinitionId}/${id}`, params, 'bearer');
};

export const updateDynamicTableRow = (collectionDefinitionId, id, body) => {
  return api.put(`/dyn_t/${collectionDefinitionId}/${id}`, body, 'bearer');
};

export const createDynamicTableRow = (collectionDefinitionId, body) => {
  return api.post(`/dyn_t/${collectionDefinitionId}`, body, 'bearer');
};

export const deleteDynamicTableRow = (collectionDefinitionId, id) => {
  return api.delete(`/dyn_t/${collectionDefinitionId}/${id}`, 'bearer');
};

export const getPublicDynamicTableRow = (collectionDefinitionId, id, params) => {
  return api.get(`/public/dyn_t/${collectionDefinitionId}/${id}`, params);
};

export const setUserObjectCache = (collectionDefinitionId, objectId) => {
  const body = {
    object_id: objectId,
  };
  return api.put(`/set_user_object_cache/${collectionDefinitionId}`, body, 'bearer');
};

export const copyDynamicTableRow = (collectionDefinitionId, id, body) => {
  return api.post(`/copy_dyn_t/${collectionDefinitionId}/${id}`, body, 'bearer');
};

export const getUserObjectCache = (collectionDefinitionId) => {
  return api.get(`/get_user_object_cache/${collectionDefinitionId}`, null, 'bearer');
};

export const removeUserObjectCache = (collectionDefinitionId, objectId) => {
  const body = {
    object_id: objectId,
  };
  return api.put(`/remove_user_object_cache/${collectionDefinitionId}`, body, 'bearer');
};
