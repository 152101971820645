import { Fragment } from 'react';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import { ErrorBoundary } from 'react-error-boundary';
import { getDynamicTableRow, getPublicDynamicTableRow } from 'api/sections';
import { sectionDefinitionComponentMapping } from 'sections';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import EditableWrapper from 'components/EditableWrapper';
import { parseJSON } from 'utils/general';

export const getSectionComponent = (section) => {
  return sectionDefinitionComponentMapping[section?.section_definition?.section_definition_id];
};

export const getDynamicFieldValue = (dynamicData, attribute) => {
  const { attribute_id, data_type, default_value } = attribute;
  let attrValue = (attribute_id in dynamicData) ? dynamicData[attribute_id] : default_value;
  switch (data_type) {
    case 1:
      attrValue = toLower(attrValue) === 'true';
      break;
    case 2:
      attrValue = Number(attrValue);
      break;
    case 4:
      attrValue = attrValue ? String(attrValue) : attrValue;
      break;
    case 8:
      attrValue = parseJSON(attrValue);
      break;
    default:
  }
  return attrValue;
};

export const getDynamicData = (dynamicObj, attributes, redirectAttributes) => {
  const jsonStrValue = get(dynamicObj, 'json_short_data') || get(dynamicObj, 'json_big_data');
  const jsonValue = parseJSON(jsonStrValue, {});
  const dynamicData = (attributes || []).reduce((map, attr) => {
    const updatedMap = { ...map };
    updatedMap[attr.name] = getDynamicFieldValue(jsonValue, attr);
    return updatedMap;
  }, {});
  if (dynamicData.redirect_collection_definition_id) {
    (redirectAttributes || []).forEach((attr) => {
      if (attr.collection_definition === dynamicData.redirect_collection_definition_id) {
        dynamicData[attr.name] = getDynamicFieldValue(jsonValue, attr);
      }
    });
  }
  return dynamicData;
};

export const fetchSectionProps = (section, isPublic) => {
  const { section_definition, dyn_t } = section || {};
  const collectionDefinition = section_definition?.collection_definition;
  const collectionDefinitionAttributes = collectionDefinition?.attributes;
  const collectionDefinitionId = collectionDefinition?.collection_definition_id;

  const fetchDynamicTableRow = isPublic ? getPublicDynamicTableRow : getDynamicTableRow;
  return ((collectionDefinitionId && dyn_t) ? (
    fetchDynamicTableRow(collectionDefinitionId, dyn_t, {})
  ) : Promise.resolve({ data: null }))
    .then(({ data }) => {
      const sectionProps = getDynamicData(data, collectionDefinitionAttributes);
      return sectionProps;
    });
};

export const renderSectionComponent = (section, sectionProps, editable, onPressEdit, userRoleScopes) => {
  const { section_id, is_viewport, scope } = section || {};
  const SectionComponent = getSectionComponent(section);
  const hasAccess = !scope || !!(userRoleScopes || []).find((roleScope) => roleScope.scope === scope);
  const { container_props, ...otherSectionProps } = sectionProps || {};
  const Viewport = is_viewport ? Container : Fragment;
  return (SectionComponent && hasAccess) ? (
    <EditableWrapper
      editable={editable}
      onPressEdit={() => onPressEdit(section_id)}
    >
      <Viewport>
        <MKBox {...container_props}>
          <ErrorBoundary
            fallback={(
              <MKTypography color="error">
                Section Render Error
              </MKTypography>
            )}
          >
            <SectionComponent
              section={section}
              editable={editable}
              onPressEdit={onPressEdit}
              {...otherSectionProps}
            />
          </ErrorBoundary>
        </MKBox>
      </Viewport>
    </EditableWrapper>
  ) : null;
};
