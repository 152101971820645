import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicTableRows, setUserObjectCache } from 'api/sections';
import { handleErrorResponse, isUuid } from 'utils/general';
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { getLocaleMap, tryGetLocalizedText } from 'utils/locales';

const AMSConfigEditSection = ({ collection_definition_id, label, optional_note, edit_redirect, edit_button_props, back_button_props, include_percentage, ...props }) => {
  const { i18n: { language } } = useTranslation();
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['edit_button', 'back_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [targetConfiguration, setTargetConfiguration] = useState(null);
  const [key, setKey] = useState(null);
  const [dynamicId, setDynamicId] = useState(null);
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = { $expand: 'attributes' };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => { setCollectionDefinition(data); })
        .catch((err) => { handleErrorResponse(err, setAuth); });
    }
  }, [collection_definition_id, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  useEffect(() => {
    if (collectionDefinition) {
      getDynamicTableRows(collectionDefinition.collection_definition_id, { $expand: 'attributes' })
        .then(({ data }) => {
          if (data.length === 0) { return; }
          const dynamicObject = data.pop();
          setDynamicId(dynamicObject.id);
          return dynamicObject;
        })
        .then((dynamicObject) => {
          const sectionProps = getDynamicData(dynamicObject, collectionDefinition.attributes);
          setTargetConfiguration(sectionProps);
          setKey(Object.keys(sectionProps).pop());
        })
        .catch((err) => { handleErrorResponse(err, setAuth); });
    }
  }, [collectionDefinition, setAuth]);

  const handleBackButtonPress = () => {
    navigate(-1);
  };

  const handleEditButtonPress = () => {
    return Promise.all([
      setUserObjectCache(collectionDefinition.collection_definition_id, dynamicId),
      (targetConfiguration?.redirect_collection_definition_id && targetConfiguration?.redirect_object_id) ? (
        setUserObjectCache(targetConfiguration.redirect_collection_definition_id, targetConfiguration.redirect_object_id)
      ) : null,
    ])
      .then(() => {
        navigate(`${edit_redirect}`, { state: { optional_note: optional_note || '' } });
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  };

  return (
    <MKBox
      {...props}
    >
      <MKTypography
        variant="body1"
        color="#000"
        sx={{ py: '1rem' }}
      >
        {tryGetLocalizedText(label, language)}
      </MKTypography>
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="text"
          size="medium"
          fontSize="16px"
          fullWidth
          onClick={() => { handleBackButtonPress(); }}
          iconOnly={false}
          disabled={false}
          circular={false}
          borderRadius={0}
          {...back_button_props}
        >
          {lm.back_button}
        </Button>

        <Button
          variant="text"
          size="medium"
          fontSize="16px"
          fullWidth
          onClick={() => { handleEditButtonPress(); }}
          iconOnly={false}
          disabled={false}
          circular={false}
          borderRadius={0}
          {...edit_button_props}
        >
          {lm.edit_button}
        </Button>

      </MKBox>
      <MKBox
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignItems="center"
        sx={{ py: '1rem' }}
      >
        <MKTypography variant="body1" fontSize="16px" color="#000">
          {optional_note ? `${startCase(key)}*:` : `${startCase(key)}:`}
        </MKTypography>
        <MKTypography variant="body1" fontSize="16px" color="#000" sx={{ ml: 2 }}>
          {`${targetConfiguration ? targetConfiguration[key] : 'N/A'} ${include_percentage ? '%' : ''}`}
        </MKTypography>
      </MKBox>
      <MKTypography variant="body1" fontSize="16px" color="#000" fontWeight="bold">
        {tryGetLocalizedText(optional_note, language)}
      </MKTypography>
    </MKBox>
  );
};

AMSConfigEditSection.propTypes = {
  collection_definition_id: PropTypes.string.isRequired,
  label: PropTypes.string,
  optional_note: PropTypes.string,
  edit_redirect: PropTypes.string,
  edit_button_props: PropTypes.object,
  back_button_props: PropTypes.object,
  include_percentage: PropTypes.bool,
};

export default AMSConfigEditSection;
