import PropTypes from 'prop-types';
import qs from 'qs';
import pick from 'lodash/pick';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import MKBox from 'components/MaterialKit/MKBox';
import Image from 'components/Image';
import { parseJSON } from 'utils/general';
import { useTranslation } from 'react-i18next';
import { tryGetLocalizedText } from 'utils/locales';

const mergePropsStr = (globalPropsStr, propsStr) => ({
  ...parseJSON(globalPropsStr, {}),
  ...parseJSON(propsStr, {}),
});

export const DrawerMenuItem = ({ menuItem, globalProps, colorPalette, ...props }) => {
  const { t, i18n: { language } } = useTranslation();
  const { type, label, new_tab, path, img_url, active_img_url, container_props, active_container_props, img_props, active_img_props, label_props, active_label_props, menu_items } = menuItem;
  const { bgColor, fontColor } = colorPalette || {};
  const location = useLocation();
  const queryParams = qs.parse((location.search || '').slice(1));
  const isActive = location.pathname?.startsWith(path);

  const containerProps = mergePropsStr(globalProps.container_props, container_props);
  const activeContainerProps = mergePropsStr(globalProps.active_container_props, active_container_props);
  const imgProps = mergePropsStr(globalProps.img_props, img_props);
  const activeImgProps = mergePropsStr(globalProps.active_img_props, active_img_props);
  const labelProps = mergePropsStr(globalProps.label_props, label_props);
  const activeLabelProps = mergePropsStr(globalProps.active_label_props, active_label_props);

  return (
    <ListItem disablePadding {...props}>
      <MKBox
        bgColor={isActive ? (bgColor || 'light') : 'transparent'}
        width="100%"
        color={fontColor}
        {...containerProps}
        {...(isActive ? activeContainerProps : {})}
      >
        <ListItemButton
          LinkComponent={Link}
          disabled={type === 1}
          {...(new_tab
            ? { onClick: () => window.open(path, '_blank') }
            : { to: `${path}?${qs.stringify(pick(queryParams, 'mode'))}` })
          }
        >
          {img_url && (
            <Image
              src={isActive && active_img_url ? active_img_url : img_url}
              alt="Menu item icon"
              sx={{ mr: '1rem' }}
              width="2rem"
              {...imgProps}
              {...(isActive ? activeImgProps : {})}
            />
          )}
          <ListItemText
            primary={tryGetLocalizedText(label, language)}
            {...labelProps}
            {...(isActive ? activeLabelProps : {})}
          />
        </ListItemButton>
        {type === 1 && (
          <List sx={{ pl: 2 }}>
            {(menu_items || []).map((subItem, i) => (
              <DrawerMenuItem
                key={`${subItem.menu_item_id}-${i}`}
                menuItem={subItem}
                globalProps={globalProps}
                colorPalette={colorPalette}
              />
            ))}
          </List>
        )}
      </MKBox>
    </ListItem>
  );
};

DrawerMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    type: PropTypes.number,
    label: PropTypes.string,
    new_tab: PropTypes.bool,
    path: PropTypes.string,
    img_url: PropTypes.string,
    active_img_url: PropTypes.string,
    active_img_props: PropTypes.string,
    menu_items: PropTypes.arrayOf(PropTypes.object),
    container_props: PropTypes.string,
    active_container_props: PropTypes.string,
    img_props: PropTypes.string,
    label_props: PropTypes.string,
    active_label_props: PropTypes.string,
  }),
  globalProps: PropTypes.shape({
    container_props: PropTypes.string,
    active_container_props: PropTypes.string,
    img_props: PropTypes.string,
    active_img_props: PropTypes.string,
    label_props: PropTypes.string,
    active_label_props: PropTypes.string,
  }),
  colorPalette: PropTypes.shape({
    fontColor: PropTypes.string,
    bgColor: PropTypes.string,
  }),
};

DrawerMenuItem.defaultProps = {
  menuItem: {},
};
