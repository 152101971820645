import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NumbersIcon from '@mui/icons-material/Numbers';
import MKBox from 'components/MaterialKit/MKBox';
import DataTable from 'components/DataTable';
import TextInput from 'components/TextInput';
import { handleErrorResponse } from 'utils/general';
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import { sendApiRequest } from 'api/general';
import { dynamicSort } from 'utils/sort';

const DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER = '__system_order__';

const TableListSection = ({
  target_url,
  pass_id,
  target_id_parameter,
  method,
  params,
  expand_fields,
  display_attributes,
  columns_config,
  type_routing,
  disable_pagination,
  button_props,
  ...props
}) => {
  const [targetUrl, setTargetUrl] = useState(null);
  const [apiResponse, setApiResponse] = useState([{}]);
  const [rowData, setRowData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER);
  const [sortDirection, setSortDirection] = useState('asc');
  const [updatedTypeRouting, setUpdatedTypeRouting] = useState(type_routing || {});
  const { auth, setAuth } = useAuth();

  const fetchDataFromApi = useCallback(() => {
    const trimmedArray = expand_fields.map((str) => str.replace(/\s/g, ''));
    const concatenatedString = trimmedArray.join(',');
    const expandable_fields = { $expand: `${concatenatedString}` };
    const request_params = {
      ...params,
      ...expandable_fields,
    };
    if (target_id_parameter) {
      request_params[target_id_parameter] = pass_id;
    }

    sendApiRequest(target_url, method, request_params, 'bearer')
      .then(({ data, headers }) => {
        setTotalDataCount(data.length);
        setApiResponse(data);

        const contentRange = get(headers, 'content-range');
        const dataCount = Number(contentRange.split('/')[1]);
        setTotalDataCount(dataCount);

        const dynamicFieldValueData = data.map((datum) => ({
          ...getDynamicData(datum, display_attributes),
          ...datum,
        }));

        const sortedRowData = dynamicFieldValueData.sort(dynamicSort(sortBy || 'createddate', sortDirection));
        setRowData(sortedRowData.slice(
          rowsPerPage * currentPage,
          rowsPerPage * (currentPage + 1),
        ));
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [currentPage, display_attributes, expand_fields, method, params, pass_id, rowsPerPage, setAuth, sortBy, sortDirection, target_id_parameter, target_url]);

  const onClickSort = useCallback((headerName) => {
    setSortBy(headerName);
    setSortDirection((previousSortDirection) => (previousSortDirection === 'asc' ? 'desc' : 'asc'));
  }, []);

  const renderSystemOrderComponent = useCallback((datum, attribute) => (
    <TextInput
      size="small"
      type="number"
      value={get(datum, attribute.name)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    />
  ), []);

  useEffect(() => {
    setUpdatedTypeRouting(type_routing);
  }, [type_routing]);

  useEffect(() => {
    if (target_url) {
      setTargetUrl(target_url);
      fetchDataFromApi();
    }
  }, [target_url, fetchDataFromApi]);

  const displayAttributes = useMemo(() => {
    if ((display_attributes || []).length > 0) {
      const res = display_attributes.map((attr) => {
        const { name } = attr;
        let { data_type } = attr;
        if (data_type === undefined) {
          data_type = 4;
        }
        const propertyNamesArray = name.split('.');
        if (propertyNamesArray.length > 1) {
          return {
            name: propertyNamesArray[propertyNamesArray.length - 1],
            nested: propertyNamesArray[0],
            data_type,
          };
        }
        return {
          name,
          data_type,
        };
      });
      return res;
    }
  }, [display_attributes]);

  const columns = useMemo(() => {
    if (!displayAttributes || displayAttributes.length === 0) {
      return [];
    }
    return displayAttributes.map((attribute) => {
      const { name, data_type } = attribute;
      let dataFormatter;
      switch (data_type) {
        case 1:
          dataFormatter = (v) => (v ? 'Yes' : 'No');
          break;
        case 4:
          dataFormatter = (v) => ((v || '').length > 50 ? `${v.substring(0, 50)}...` : v);
          break;
        case 8:
          dataFormatter = (v) => JSON.stringify(v);
          break;
        default:
          break;
      }
      return {
        field: name,
        ...(name === DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER ? {
          label: <NumbersIcon fontSize="small" />,
          renderContent: (datum) => renderSystemOrderComponent(datum, attribute),
          width: '0',
        } : {}),
        ...(dataFormatter ? {
          formatter: dataFormatter,
        } : {}),
        ...(columns_config && columns_config[name] ? columns_config[name] : {}),
      };
    });
  }, [displayAttributes, columns_config, renderSystemOrderComponent]);

  const parsedRowData = useMemo(() => rowData.map((rowDatum) => {
    const { ...restDatum } = rowDatum;
    const parsedRowDatum = displayAttributes.reduce((result, attribute) => {
      const { name, nested } = attribute;
      if (nested) {
        const nestedValue = get(rowDatum, nested);
        return {
          ...result,
          [name]: nestedValue ? get(nestedValue, name) : '',
        };
      }
      return {
        ...result,
        [name]: get(rowDatum, name),
      };
    }, {});
    return {
      ...parsedRowDatum,
      ...restDatum,
    };
  }), [rowData, displayAttributes]);

  // const consoleLogTableInfo = () => {
  //   console.log('target_url: ', target_url);
  //   console.log('pass_id: ', pass_id);
  //   console.log('target_id_parameter: ', target_id_parameter);
  //   console.log('method: ', method);
  //   console.log('displayAttributes', displayAttributes);
  //   console.log('columns', columns);
  //   console.log('parsedRowData', parsedRowData);
  // };

  return (
    <MKBox>
      {/* <button
        type="submit"
        onClick={consoleLogTableInfo}
      >
        Table information
      </button> */}
      <DataTable
        columns={columns}
        data={parsedRowData}
        typeRouting={updatedTypeRouting}
        totalCount={totalDataCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={setRowsPerPage}
        paginationDisabled={disable_pagination}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
        pageButtonProps={button_props || {}}
      />
    </MKBox>
  );
};

TableListSection.propTypes = {
  target_url: PropTypes.string,
  target_id_parameter: PropTypes.string,
  pass_id: PropTypes.string,
  method: PropTypes.string,
  params: PropTypes.object,
  expand_fields: PropTypes.arrayOf(PropTypes.string),
  display_attributes: PropTypes.arrayOf(PropTypes.Object),
  columns_config: PropTypes.object,
  type_routing: PropTypes.object,
  disable_pagination: PropTypes.bool,
  button_props: PropTypes.object,
};

export default TableListSection;
