import { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import toLower from 'lodash/toLower';
import partition from 'lodash/partition';
import startCase from 'lodash/startCase';
import Grid from '@mui/material/Grid';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import EditAttributeField from 'components/EditAttributeField';
import Button from 'components/Button';
import Select from 'components/Select';
import { getDynamicTableRows } from 'api/sections';
import { getDynamicData } from 'utils/sections';
import { useTranslation } from 'react-i18next';
import { handleErrorResponse, isUuid, parseJSON } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getFiles } from 'api/files';
import { getLocaleMap } from 'utils/locales';

const ATTRIBUTE_SUFFIX_IS_REDIRECT = '.is_redirect';
const ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID = '.target_collection_definition_id';
const ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID = '.target_attribute_id';

const ATTRIBUTE_SUFFIX_USE_LOCALES = '.use_locales';
const locales_collection_definition_id = '73c6d4a4-4014-4df9-9f9a-f82902418eef';

const ATTRIBUTE_SUFFIX_IS_FROM_COLLECTION = '.is_from_collection';
const ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID = '.from_collection_definition_id';
const ATTRIBUTE_SUFFIX_FILE_URL = '.file_url';
const ATTRIBUTE_VALUE_ID = 'value';
const ATTRIBUTE_NAME = 'name';

const EditAttributesForm = ({ attributes, config, collectionDefinitions, onSave, onCancel, ...props }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['cancel_button', 'back_button', 'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const [rows, setRows] = useState([]);

  const fetchDynamicRows = useCallback((id, localesAttributes) => {
    if (!id) return;
    return getDynamicTableRows(id)
      .then(({ data }) => {
        const dynamicData = data.map((row) => {
          const dynamic = getDynamicData(row, localesAttributes);
          if (!dynamic.is_used) {
            return;
          }
          dynamic.id = row.id;
          return dynamic;
        }).filter(Boolean);
        return dynamicData;
      });
  }, []);

  const preparetSave = (values) => {
    const submittableValues = {
      ...values,
    };
    const localizedAttributes = attributes.map((attribute) => { if (attribute.name?.endsWith(ATTRIBUTE_SUFFIX_USE_LOCALES)) return attribute; return null; }).filter(Boolean);
    localizedAttributes.forEach((localizedAttribute) => {
      const text_attribute_field_id = attributes.find((attribute) => attribute?.name === localizedAttribute.name.replace('.use_locales', ''))?.attribute_id;
      if (!text_attribute_field_id) return;
      const localized_text = {};
      if (values[localizedAttribute.attribute_id] !== 'true') {
        submittableValues[text_attribute_field_id] = values[text_attribute_field_id];
        rows.forEach((row) => {
          delete submittableValues[row.id + text_attribute_field_id];
        });
        return;
      }
      rows.forEach((row) => {
        localized_text[row.value] = values[row.id + text_attribute_field_id];
        delete submittableValues[row.id + text_attribute_field_id];
        submittableValues[text_attribute_field_id] = JSON.stringify(localized_text);
      });
    });
    onSave(submittableValues);
  };

  const prepareInitialValues = useCallback((values) => {
    const mod = { ...values };
    const localizedAttribute = attributes.map((attribute) => { if (attribute.name?.endsWith(ATTRIBUTE_SUFFIX_USE_LOCALES)) return attribute.name; return null; }).filter(Boolean);
    localizedAttribute.forEach((localizedAttributeName) => {
      const text_attribute_field_id = attributes.find((attribute) => attribute?.name === localizedAttributeName.replace('.use_locales', ''))?.attribute_id;
      if (!text_attribute_field_id) return;
      try {
        const localized_text = JSON.parse(values[text_attribute_field_id]);
        rows.forEach((row) => {
          const bigKey = row.id + text_attribute_field_id;
          mod[bigKey] = localized_text[row.value];
        });
      } catch (e) {
        // console.error('Error parsing JSON', e);
      }
    });
    return mod;
  }, [attributes, rows]);

  const prepareRows = useCallback(() => {
    const locales = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === locales_collection_definition_id);
    if (locales) {
      fetchDynamicRows(locales.collection_definition_id, locales.attributes)
        .then((dynamicData) => {
          setRows(dynamicData);
        });
    }
  }, [collectionDefinitions, fetchDynamicRows]);

  useEffect(() => {
    prepareRows();
  }, [prepareRows]);

  const [fromCollectionSelectOptions, setFromCollectionSelectOptions] = useState([]);
  const { auth, setAuth } = useAuth();
  const [preselectedFromCollection, setPreselectedFromCollection] = useState(null);

  const fetchAssetsOptionsFromCollection = useCallback((collectionId) => {
    const tmpCol = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === collectionId);
    if (!tmpCol) return Promise.resolve();
    const value = tmpCol?.attributes.find((attr) => attr.name === ATTRIBUTE_VALUE_ID)?.attribute_id;
    const name = tmpCol?.attributes.find((attr) => attr.name === ATTRIBUTE_NAME)?.attribute_id;
    return getDynamicTableRows(collectionId, {})
      .then(({ data }) => {
        const fileIds = data.map((row) => {
          const jsonData = parseJSON(row.json_short_data, {});
          if (!jsonData[value] || !jsonData[name]) { return; } // Omit return statement for cases where id or name is missing
          return { id: jsonData[value], name: jsonData[name] };
        }).filter(Boolean); // Filter out undefined values
        return fileIds;
      })
      .then((fileIds) => {
        if (!fileIds.length) {
          // eslint-disable-next-line no-alert
          alert('Invalid collection definition. Does not have any options available. Please try another one, or check what may be wrong.'); 
          return Promise.resolve([]);
        }
        const files = getFiles({ 'file_id[in]': fileIds.map((file) => file.id).join(',') })
          .then(({ data }) => {
            return data.map((file) => {
              const option = {};
              option.label = fileIds.find((f) => f.id === file.file_id).name;
              option.value = file.file_url;
              return option;
            });
          });
        return files;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinitions, setAuth]);

  const onFromCollectionChange = useCallback((v) => {
    fetchAssetsOptionsFromCollection(v)
      .then((options) => {
        setFromCollectionSelectOptions(options || []);
      });
  }, [fetchAssetsOptionsFromCollection]);

  const initialValues = useMemo(() => {
    return (attributes || []).reduce((map, attr) => {
      const { attribute_id, default_value } = attr;
      const updatedMap = { ...map };
      updatedMap[attribute_id] = (config && attribute_id in config) ? config[attribute_id] : default_value;
      return prepareInitialValues(updatedMap);
    }, {});
  }, [attributes, config, prepareInitialValues]);

  const [mainAttributes, redirectAttributes] = useMemo(() => partition(attributes, (attribute) => {
    const attributeName = attribute?.name || '';
    return !(attributeName.endsWith(ATTRIBUTE_SUFFIX_IS_REDIRECT)
      || attributeName.endsWith(ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID)
      || attributeName.endsWith(ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID)
      || attributeName.endsWith(ATTRIBUTE_SUFFIX_USE_LOCALES)
    );
  }), [attributes]);

  const [restAttributes, fromCollectionAttributes] = useMemo(() => partition(mainAttributes, (attribute) => {
    const attributeName = attribute?.name || '';
    return !(attributeName.endsWith(ATTRIBUTE_SUFFIX_IS_FROM_COLLECTION) || attributeName.endsWith(ATTRIBUTE_SUFFIX_FILE_URL) || attributeName.endsWith(ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID));
  }), [mainAttributes]);

  useEffect(() => {
    const fromCollectionAttributeId = attributes.find((attr) => attr.name.endsWith(ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID))?.attribute_id;
    setPreselectedFromCollection(initialValues[fromCollectionAttributeId]);
  }, [attributes, initialValues]);

  useEffect(() => {
    if (isUuid(preselectedFromCollection)) {
      fetchAssetsOptionsFromCollection(preselectedFromCollection)
        .then((options) => {
          setFromCollectionSelectOptions(options || []);
        });
    }
  }, [preselectedFromCollection, fetchAssetsOptionsFromCollection]);

  return (
    <Formik
      onSubmit={preparetSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            {(restAttributes || []).sort(
              (a1, a2) => a1.sequence - a2.sequence,
            ).map((attribute) => {
              const { attribute_id, name } = attribute;
              const isRedirectAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_IS_REDIRECT}`);
              const targetCollectionDefinitionIdAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID}`);
              const targetAttributeIdAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID}`);

              const isCollAttribute = fromCollectionAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_IS_FROM_COLLECTION}`);
              const ifcCollectionDefinitionIdAttribute = fromCollectionAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_FROM_COLLECTION_DEFINITION_ID}`);
              const ifcAttributeIdAttribute = fromCollectionAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_FILE_URL}`);

              const isRedirect = toLower(values[isRedirectAttribute?.attribute_id]) === 'true';
              const isFromCollection = toLower(values[isCollAttribute?.attribute_id]) === 'true';

              const targetCollectionDefinition = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === values[targetCollectionDefinitionIdAttribute?.attribute_id]);

              const isUseLocalesAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_USE_LOCALES}`);
              let useLocales = toLower(values[isUseLocalesAttribute?.attribute_id]) === 'true';
              if (isRedirect) {
                useLocales = false;
              }
              const ifcCollectionDefinition = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === values[ifcCollectionDefinitionIdAttribute?.attribute_id]);

              const targetCollectionDefinitionOptions = collectionDefinitions.map((collectionDefinition) => ({
                label: collectionDefinition.name,
                value: collectionDefinition.collection_definition_id,
              }));
              const ifcCollectionDefinitionOptions = collectionDefinitions.map((collectionDefinition) => ({
                label: collectionDefinition.name,
                value: collectionDefinition.collection_definition_id,
              }));

              const targetCollectionDefinitionAttributeOptions = [
                {
                  label: 'ID',
                  value: 'id',
                },
                ...(targetCollectionDefinition?.attributes || []).map((targetAttribute) => ({
                  label: targetAttribute.name === '__system_order__' ? '# System Order' : targetAttribute.name,
                  value: targetAttribute.attribute_id,
                })),
                {
                  label: 'Created Date',
                  value: 'createddate',
                },
                {
                  label: 'Last Modified Date',
                  value: 'lastmoddate',
                },
                {
                  label: 'Created By',
                  value: 'created_by',
                },
                {
                  label: 'Last Modified By',
                  value: 'modified_by',
                },
              ];

              return (
                <MKBox key={attribute_id} mb={2}>
                  <MKBox display="flex" flexDirection="row">
                    <MKBox flex={4}>
                      <EditAttributeField
                        attribute={attribute}
                        value={(!isRedirect && !useLocales) ? values[attribute_id] : '-'}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        disabled={isFromCollection}
                      />
                    </MKBox>
                    {!!isRedirectAttribute && (
                      <MKBox ml={1} flex={1}>
                        <EditAttributeField
                          attribute={isRedirectAttribute}
                          value={values[isRedirectAttribute.attribute_id]}
                          label={startCase(isRedirectAttribute.name.replace(`${name}.`, ''))}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </MKBox>
                    )}
                    {!!isUseLocalesAttribute && toLower(values[isRedirectAttribute?.attribute_id]) !== 'true' && (
                      <MKBox ml={1} flex={1}>
                        <EditAttributeField
                          attribute={isUseLocalesAttribute}
                          value={values[isUseLocalesAttribute.attribute_id]}
                          label={startCase(isUseLocalesAttribute.name.replace(`${name}.`, ''))}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </MKBox>
                    )}
                    {!!isCollAttribute && (
                      <MKBox ml={1} flex={1}>
                        <EditAttributeField
                          attribute={isCollAttribute}
                          value={values[isCollAttribute.attribute_id]}
                          label={startCase(isCollAttribute.name.replace(`${name}.`, ''))}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </MKBox>
                    )}
                  </MKBox>
                  {isRedirect ? (
                    <MKBox mt={1} ml={2}>
                      {!!targetCollectionDefinitionIdAttribute && (
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={targetCollectionDefinitionIdAttribute.attribute_id}
                            label={startCase(targetCollectionDefinitionIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[targetCollectionDefinitionIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(targetCollectionDefinitionIdAttribute.attribute_id, v)}
                            options={targetCollectionDefinitionOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                      {!!targetAttributeIdAttribute && (
                        <MKBox mt={1} display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={targetAttributeIdAttribute.attribute_id}
                            label={startCase(targetAttributeIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[targetAttributeIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(targetAttributeIdAttribute.attribute_id, v)}
                            disabled={!targetCollectionDefinition?.attributes?.length}
                            options={targetCollectionDefinitionAttributeOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                    </MKBox>
                  ) : null}
                  {isUseLocalesAttribute && useLocales && rows.map((row) => {
                    const att = {
                      attribute_id: row.id + attribute_id,
                      name: row.name,
                      data_type: 4,
                    };
                    return (
                      <MKBox key={row.id} mt={1} ml={4} display="flex" flexDirection="row" alignItems="center">
                        <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                        <EditAttributeField
                          attribute={att}
                          label={row.name}
                          value={values[row.id + attribute_id]}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={isRedirect}
                        />
                      </MKBox>
                    );
                  })}
                  {isFromCollection ? (
                    <MKBox mt={1} ml={2}>
                      {!!ifcCollectionDefinitionIdAttribute && (
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={ifcCollectionDefinitionIdAttribute.attribute_id}
                            label={startCase(ifcCollectionDefinitionIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[ifcCollectionDefinitionIdAttribute.attribute_id] || ''}
                            onChange={(v) => { onFromCollectionChange(v); setFieldValue(ifcCollectionDefinitionIdAttribute.attribute_id, v); }}
                            options={ifcCollectionDefinitionOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                      {!!ifcAttributeIdAttribute && (
                        <MKBox mt={1} display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={ifcAttributeIdAttribute.attribute_id}
                            label={startCase(ifcAttributeIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[ifcAttributeIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(ifcAttributeIdAttribute.attribute_id, v)}
                            disabled={!ifcCollectionDefinition?.attributes?.length}
                            options={fromCollectionSelectOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                    </MKBox>
                  ) : null}
                </MKBox>
              );
            })}
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      {dirty ? lm.cancel_button : lm.back_button}
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting || !dirty}
                    >
                      {lm.save_button}
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditAttributesForm.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      attribute_id: PropTypes.string,
      name: PropTypes.any,
      data_type: PropTypes.number,
      default_value: PropTypes.any,
    }),
  ),
  config: PropTypes.object,
  collectionDefinitions: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditAttributesForm.defaultProps = {
  attributes: [],
  config: {},
  collectionDefinitions: [],
  onSave: () => {},
  onCancel: () => {},
};

export default EditAttributesForm;
