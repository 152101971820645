import api from 'api';

export const uploadAMSFiles = (endpoint, body, queryParams) => {
  const urlParams = new URLSearchParams(queryParams);
  const urlParamsStr = urlParams.toString();
  const url = `/${endpoint}${(urlParamsStr === '' ? '' : '?')}${urlParamsStr}`;
  return api.postMultipart(url, body, 'bearer');
};

export const disableEnableMetadata = () => {
  const url = '/disable_enable_metadata';
  return api.post(url, null, 'bearer');
};

export const createAMSReport = (endpoint, body) => {
  return api.postMultipart(`/${endpoint}`, body, 'bearer');
};
