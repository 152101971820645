import PropTypes from 'prop-types';
import Logs from 'components/Logs';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { getCollectionDefinition, getCollectionDefinitions } from 'api/collection_definitions';
import { getDynamicTableRows } from 'api/sections';
import { handleErrorResponse, isUuid } from 'utils/general';
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { tryGetLocalizedText } from 'utils/locales';

const LogsSection = ({
  collection_definition_id,
  object_id,
  log_title,
  export_button_title,
  export_button_props,
  use_date_filter,
  use_error_filter,
  use_user_filter,
  exportable,
  extensions,
  sort_by,
  font_size,
  editable,
  onPressEdit,
  data_limit,
  ...props }) => {
  const { i18n: { language } } = useTranslation();
  const [collectionDefinitions, setCollectionDefinitions] = useState([]);
  const [content, setContent] = useState([]);
  const { auth, setAuth } = useAuth();

  const fetchDataFromApi = useCallback((fromDate, toDate) => {
    const collectionDefinitionAttributes = [];
    if (collectionDefinitions.length > 0) {
      const params = {
        $top: data_limit,
        $orderby: sort_by || 'createddate',
        ...(fromDate && { 'createddate[gte]': fromDate }),
        ...(toDate && { 'createddate[lte]': toDate }),
        ...(!fromDate && !toDate && { 'createddate[gte]': moment().subtract(1, 'days').valueOf() }),
      };
      Promise.all(collectionDefinitions.map((collectionDefinition) => {
        collectionDefinitionAttributes.push(collectionDefinition.attributes);
        return getDynamicTableRows(collectionDefinition.collection_definition_id, params);
      }))
        .then((promises) => {
          let dynamicContent = [];
          promises.forEach(({ data }, index) => {
            const dynamicFieldValueData = data.map((datum) => ({
              ...getDynamicData(datum, collectionDefinitionAttributes[index]),
              ...datum,
            }));
            dynamicContent = [...dynamicContent, ...dynamicFieldValueData];
          });
          setContent(dynamicContent.sort((a, b) => b.createddate - a.createddate));
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collectionDefinitions, setAuth, sort_by]);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (collection_definition_id) {
      const collectionDefinitionIds = collection_definition_id.replace(/\s/g, '').split(',');
      const collectionDefinitionParams = {
        '$expand': 'attributes',
        'collection_definition_id[in]': collectionDefinitionIds.join(),
      };
      return getCollectionDefinitions(collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinitions(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  useEffect(() => {
    if (collectionDefinitions.length > 0) {
      fetchDataFromApi();
    }
  }, [collectionDefinitions, fetchDataFromApi]);

  const fontSizeValue = useMemo(() => (Number.isNaN(Number(font_size)) ? font_size : Number(font_size)), [
    font_size,
  ]);

  const logTitleText = tryGetLocalizedText(log_title, language);
  const buttonTitleText = tryGetLocalizedText(export_button_title, language);

  return (
    <Logs
      logTitle={logTitleText}
      exportButtonTitle={buttonTitleText}
      exportButtonProps={export_button_props}
      useDateFilter={use_date_filter}
      useErrorFilter={use_error_filter}
      useUserFilter={use_user_filter}
      useObjectFilter={!!object_id}
      objectFilter={object_id}
      exportable={exportable}
      extensions={extensions}
      sortBy={sort_by}
      content={content}
      fontSize={fontSizeValue}
      onChangeDateFilter={fetchDataFromApi}
      {...props}
    />
  );
};

LogsSection.propTypes = {
  collection_definition_id: PropTypes.string,
  object_id: PropTypes.string,
  log_title: PropTypes.string,
  export_button_title: PropTypes.string,
  export_button_props: PropTypes.object,
  use_user_filter: PropTypes.bool,
  use_error_filter: PropTypes.bool,
  use_date_filter: PropTypes.bool,
  exportable: PropTypes.bool,
  extensions: PropTypes.array,
  sort_by: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
  data_limit: PropTypes.string,
};

export default LogsSection;
