import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from 'contexts/auth';
import TextSection from 'sections/Text';
import { getDynamicTableRows } from 'api/sections';
import { handleErrorResponse } from 'utils/general';

const CollectionDefinitioRowCounter = ({ collection_definition_id, params, editable, onPressEdit, ...props }) => {
  const [text, setText] = useState('0');
  const { setAuth } = useAuth();

  const fetchRowCount = useCallback(() => {
    const parameteres = { ...params };
    return getDynamicTableRows(collection_definition_id, parameteres, 'bearer')
      .then(({ data }) => {
        // eslint-disable-next-line no-console
        setText(data?.length);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collection_definition_id, params, setAuth]);

  useEffect(() => {
    if (collection_definition_id) {
      fetchRowCount();
    }
  }, [collection_definition_id, fetchRowCount]);

  if (!text) {
    return null;
  }
  return (
    <TextSection
      text={text}
      variant="body1"
      color="black"
      is_rich_text={false}
      {...props}
    />
  );
};

CollectionDefinitioRowCounter.propTypes = {
  collection_definition_id: PropTypes.string,
  params: PropTypes.object,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default CollectionDefinitioRowCounter;
