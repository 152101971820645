import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { datetimeFormatter } from 'utils/datetime';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import Papa from 'papaparse';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import InputField from 'components/InputField';
import { getLocaleMap } from 'utils/locales';
import { getUsers } from 'api/users';

const Logs = ({
  logTitle,
  exportButtonTitle,
  exportButtonProps,
  useDateFilter,
  useErrorFilter,
  useUserFilter,
  useObjectFilter,
  objectFilter,
  exportable,
  extensions,
  sortBy,
  content,
  fontSize,
  onChangeDateFilter,
  ...props
}) => {
  const errorOptions = [
    { label: 'All Errors', value: '' },
    { label: 'Upload Fail', value: '5b8dcb4d-a35c-4ed7-9aaa-7f3c8695dfe6' },
    { label: 'Repeated ISRC', value: 'a77c5b00-c8a5-425c-a0f6-4b2588921dfc' },
    { label: 'Process Fail', value: '018e314f-2c38-7995-b3e4-b68193b23445' },
  ];

  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_no_logs_found', 'labels_user', 'labels_from', 'labels_to', 'labels_error_type'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [errorFilter, setErrorFilter] = useState(null);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    setFromDate(moment().startOf('day').subtract(1, 'days'));
    setToDate(moment().endOf('day'));
  }, []);

  useEffect(() => {
    getUsers().then(({ data }) => {
      const users = [{ label: 'All Users', value: '' }];
      data.forEach((datum) => {
        users.push({ label: datum.display_name, value: datum.username });
      });
      setUserOptions(users);
    });
  }, []);

  const filteredContent = useMemo(() => {
    return content.filter((datum) => {
      let ret = true;
      // if (useDateFilter) {
      //   ret = ret && (!fromDate || datumDate.isSameOrAfter(fromDate.startOf('day'))) && (!toDate || datumDate.isSameOrBefore(toDate.endOf('day')));
      // }
      if (useUserFilter) {
        ret = ret && (!userFilter || datum.username.includes(userFilter));
      }
      if (useErrorFilter) {
        ret = ret && (!errorFilter || datum.error_type === errorFilter);
      }
      if (useObjectFilter) {
        ret = ret && (!objectFilter || datum.object_id === objectFilter);
      }
      return ret;
    });
  }, [content, useObjectFilter, objectFilter, useUserFilter, useErrorFilter, userFilter, errorFilter]);

  const handleUserInputChange = (name, user) => {
    setUserFilter(user);
  };

  const handleErrorInputChange = (name, errorId) => {
    setErrorFilter(errorId);
  };

  const handleCsvExport = () => {
    const csv = Papa.unparse(filteredContent);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const date = moment().format('YYYY-MM-DD_hh:mm:ss');
    link.download = `${logTitle}_${date}_logs.csv`;
    link.href = url;
    link.click();
  };

  const handlePdfExport = () => {
  // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'landscape',
      fontSize: 10,
    });

    const data = filteredContent.map((item) => [
      item.createddate ? datetimeFormatter(item.createddate, 'YYYY-MM-DD, HH:mm:ss') : 'Unknown Date',
      item.display_name || 'Unknown User',
      item.description || 'Unknown Action',
    ]);

    const headers = [['Date', 'User', 'Action']];
    doc.autoTable({
      head: headers,
      body: data,
      startY: 5,
      columnWidth: [50, 50, 100],
      margin: { top: 2.5, left: 5, right: 5, bottom: 2.5 },
    });

    const date = moment().format('YYYY-MM-DD_hh:mm:ss');
    doc.save(`${logTitle}_${date}_logs.pdf`);
  };

  const handleExportButtonClick = (name) => {
    // ==Todo==
    switch (name) {
      case 'csv':
        handleCsvExport();
        break;
      case 'pdf':
        handlePdfExport();
        break;
      default:
        break;
    }
  };

  const exportButtons = () => {
    return (
      <MKBox
        display="flex"
        justifyContent="end"
        width="50%"
        alignItems="center"
      >
        {extensions && extensions.map((extension, index) => {
          return (
            <Button
              key={index}
              variant="text"
              size="medium"
              fontSize="16px"
              fullWidth
              onClick={() => handleExportButtonClick(extension)}
              iconOnly={false}
              disabled={false}
              circular={false}
              {...exportButtonProps}
            >
              {`${exportButtonTitle} (${extension.toUpperCase()})`}
            </Button>
          );
        })}
      </MKBox>
    );
  };

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      {...props}
    >
      {/* Title and Button section */}
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="auto"
        width="100%"
        py="0.5rem"
      >
        <MKTypography variant="h6">{`${logTitle} (${(filteredContent || []).length}) `}</MKTypography>
        {exportButtons()}
      </MKBox>

      {/* Controls section */}
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="auto"
        width="100%"
        py="1rem"
      >
        {useUserFilter && (
          <MKBox
            display="flex"
            flex="1"
            justifyContent="start"
            alignItems="center"
          >
            <MKTypography variant="h6" sx={{ marginRight: 1 }}>{`${lm.labels_user}:`}</MKTypography>
            <InputField
              label=""
              displayEmpty
              value={userFilter || ''}
              type="select"
              options={userOptions}
              setFieldValue={handleUserInputChange}
            />
          </MKBox>
        )}
        {useDateFilter && (
          <MKBox
            display="flex"
            flex="2"
            justifyContent={useErrorFilter ? 'start' : 'end'}
            alignItems="center"
          >
            <MKBox
              display="flex"
              alignItems="center"
            >
              <MKTypography variant="h6" sx={{ marginRight: 1 }}>{`${lm.labels_from}:`}</MKTypography>
              <DatePicker
                type="from"
                dateFormat="YYYY-MM-DD"
                useUnixTimestamp={false}
                selectedDate={fromDate}
                disableFuture
                onDateChange={(date) => {
                  setFromDate(date);
                  onChangeDateFilter(date.startOf('day').valueOf(), toDate.valueOf());
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '50px',
                  minWidth: '150px',
                }}
              />
            </MKBox>
            <MKBox
              display="flex"
              alignItems="center"
            >
              <MKTypography variant="h6" sx={{ marginX: 1 }}>{`${lm.labels_to}:`}</MKTypography>
              <DatePicker
                type="to"
                dateFormat="YYYY-MM-DD"
                useUnixTimestamp={false}
                selectedDate={toDate}
                disableFuture
                minDate={fromDate}
                onDateChange={(date) => {
                  setToDate(date);
                  onChangeDateFilter(fromDate.valueOf(), date.endOf('day').valueOf());
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '50px',
                  minWidth: '150px',
                }}
              />
            </MKBox>
          </MKBox>
        )}
        {useErrorFilter && (
          <MKBox
            display="flex"
            flex="1"
            justifyContent="end"
            alignItems="center"
          >
            <MKTypography variant="h6" sx={{ marginRight: 1 }}>{`${lm.labels_error_type}:`}</MKTypography>
            <InputField
              label=""
              displayEmpty
              value={errorFilter || ''}
              type="select"
              options={errorOptions}
              setFieldValue={handleErrorInputChange}
            />
          </MKBox>
        )}
      </MKBox>
      {/* Text Area Section */}
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="left"
        paddingX="0.5rem"
        paddingY="0.25rem"
        height="50vh"
        width="100%"
        sx={{
          border: '1px solid #000',
          overflow: 'auto',
          flexGrow: 1,
        }}
      >
        {Array.isArray(filteredContent) && filteredContent.length > 0 && (
          filteredContent.map((datum, index) => (
            <MKTypography key={index} fontSize={fontSize}>
              {datum.createddate ? datetimeFormatter(datum.createddate, 'YYYY-MM-DD, HH:mm:ss') : 'Unknown Date'}
              {' ('}
              {datum.display_name || 'Unknown User'}
              {'): '}
              {datum.description || 'Unknown Action'}
            </MKTypography>
          )))}
        {Array.isArray(filteredContent) && filteredContent.length === 0 && <MKTypography fontSize={fontSize}>{lm.labels_no_logs_found}</MKTypography>}

      </MKBox>
    </MKBox>
  );
};

Logs.propTypes = {
  logTitle: PropTypes.string,
  exportButtonTitle: PropTypes.string,
  exportButtonProps: PropTypes.object,
  useDateFilter: PropTypes.bool,
  useErrorFilter: PropTypes.bool,
  useUserFilter: PropTypes.bool,
  useObjectFilter: PropTypes.bool,
  objectFilter: PropTypes.string,
  exportable: PropTypes.bool,
  extensions: PropTypes.array,
  sortBy: PropTypes.string,
  content: PropTypes.array,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeDateFilter: PropTypes.func,
};

Logs.defaultProps = {
  logTitle: null,
  exportButtonTitle: null,
  exportButtonProps: null,
  useDateFilter: null,
  useErrorFilter: null,
  useUserFilter: null,
  useObjectFilter: null,
  objectFilter: null,
  exportable: null,
  extensions: null,
  sortBy: null,
  content: [],
  fontSize: null,
  onChangeDateFilter: () => {},
};

export default Logs;
