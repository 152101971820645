import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { getLocaleMap } from 'utils/locales';

const SetPasswordForm = ({ onSave, ...props }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_new_password', 'labels_confirm_new_password', 'errors_required', 'errors_password_not_match', 'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const getFormValidation = (values) => {
    const { password, confirm_password } = values;
    const errors = {};

    if (!password) {
      errors.password = lm.errors_required;
    }
    if (!confirm_password) {
      errors.confirm_password = lm.errors_required;
    }
    if (password && confirm_password && password !== confirm_password) {
      errors.confirm_password = lm.errors_password_not_match;
      errors.password = lm.errors_password_not_match;
    }
    return errors;
  };

  const initialValues = useMemo(() => ({
    password: '',
    confirm_password: '',
  }), []);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      validate={getFormValidation}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, touched, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <InputField
                  type="password"
                  name="password"
                  label={lm.labels_new_password}
                  value={values.password}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.password && !!errors.password}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.password && !!errors.password) ? errors.password : '\xa0'}
                </MKTypography>
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  type="password"
                  name="confirm_password"
                  label={lm.labels_confirm_new_password}
                  value={values.confirm_password}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.confirm_password && !!errors.confirm_password}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.confirm_password && !!errors.confirm_password) ? errors.confirm_password : '\xa0'}
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : lm.save_button}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

SetPasswordForm.propTypes = {
  onSave: PropTypes.func,
};

SetPasswordForm.defaultProps = {
  onSave: () => {},
};

export default SetPasswordForm;
