import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NumbersIcon from '@mui/icons-material/Numbers';
import MKBox from 'components/MaterialKit/MKBox';
import DataTable from 'components/DataTable';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { handleErrorResponse } from 'utils/general';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import { sendApiRequest } from 'api/general';
import { dynamicSort } from 'utils/sort';
import MultipleSelect from 'components/MultipleSelect';
import SearchBox from 'components/SearchBox';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { withLoading } from 'utils/hoc';
import { tryGetLocalizedText, getLocaleMap } from 'utils/locales';
import { useTranslation } from 'react-i18next';
import { Promise } from 'bluebird';
import { DATE_FORMAT_DATE_TIME_DEFAULT, datetimeFormatter } from 'utils/datetime';

const DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER = '__system_order__';

const AMSFilterSearchTable = ({ target_url, pass_id, target_id_parameter, method, params,
  expand_fields, display_attributes, columns_config, type_routing,
  use_add_button, add_button_path, add_button_label, add_button_props,
  use_delete_button, delete_button_path, delete_button_label, delete_button_props,
  use_search_box, search_box_placeholder, search_box_props, search_box_input_props,
  use_select_component, select_box_label, select_box_props, select_box_parameter, select_box_font_styles,
  use_search_button, search_button_label, search_button_props, search_box_parameter,
  use_mapping, mapping_params, mapping_url, map_by, map_target_parameter, mapping_display_attributes,
  setLoading, select_box_options, search_button_csv_mapping_to_parameter, fingerprint_id,
  header_background_color, row_background_color,
  ...props
}) => {
  const { i18n: { language } } = useTranslation();
  const [rowData, setRowData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER);
  const [sortDirection, setSortDirection] = useState('asc');
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_clear', 'labels_search_batch', 'labels_items'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const [file, setFile] = useState(null);
  const [array, setArray] = useState([]);
  const fileReader = useMemo(() => { return new FileReader(); }, []);

  const [searchField, setSearchField] = useState('');
  const [selectFields, setSelectFields] = useState([]);

  const setRowAndDataCount = useCallback((data, headers, attributes) => {
    const contentRange = get(headers, 'content-range');
    const dataCount = Number(contentRange.split('/')[1]);
    setTotalDataCount(dataCount);
    const dynamicFieldValueData = data.map((datum) => ({ ...getDynamicData(datum, attributes), ...datum }));
    const sortedRowData = dynamicFieldValueData.sort(dynamicSort(sortBy || 'createddate', sortDirection));
    setRowData(sortedRowData.slice(rowsPerPage * currentPage, rowsPerPage * (currentPage + 1)));
  }, [currentPage, rowsPerPage, sortBy, sortDirection]);

  const chunk = useCallback((targetArray, chunk_size) => {
    if (targetArray.length === 0) return [];
    return [targetArray.splice(0, chunk_size)].concat(chunk(targetArray, chunk_size));
  }, []);

  const fetchMappedData = useCallback((request_params, mapped) => {
    const map_request_params = { ...request_params };
    if (request_params[map_by] === undefined) {
      const targets = mapped.map((datum) => get(datum, map_target_parameter).replace('.wav', ''));
      if (targets.length === 0) { setRowData([]); setTotalDataCount(0); setCurrentPage(0); return; }
      if (array.length > 0) {
        const matchedTargets = array.filter((target) => targets.includes(target));
        map_request_params[map_by] = matchedTargets.join(',');
      }
      if (map_request_params[map_by] === undefined) {
        map_request_params[map_by] = targets.join(',');
      }
      if (fingerprint_id && !map_request_params.metadata_id) {
        map_request_params.metadata_id = fingerprint_id;
      }
      if (!fingerprint_id) {
        return Promise.resolve();
      }
    }
    if (map_request_params['isrc[in]']) {
      const isrcList = map_request_params['isrc[in]'].split(',');
      map_request_params.$orderby = 'createddate desc'
      const chunkIsrcList = chunk(isrcList, 50);
      setLoading(true);
      Promise.map(chunkIsrcList, (list) => {
        map_request_params['isrc[in]'] = list.join(',');
        return sendApiRequest(mapping_url, 'get', map_request_params, 'bearer');
      }, { concurrency: 3 }).then((promises) => {
        const dataList = [];
        const temporaryHeader = {};
        promises.forEach(({ data }) => {
          dataList.push(...data);
        });
        temporaryHeader['content-range'] = `${dataList.length}/${dataList.length}`;
        setRowAndDataCount(dataList, temporaryHeader, mapping_display_attributes);
        setLoading(false);
      });
    } else {
      return sendApiRequest(mapping_url, 'get', map_request_params, 'bearer')
        .then(({ data, headers }) => { setRowAndDataCount(data, headers, mapping_display_attributes); });
    }
  }, [array, chunk, fingerprint_id, map_by, map_target_parameter, mapping_display_attributes, mapping_url, setLoading, setRowAndDataCount]);

  const prepareRequestParams = useCallback(() => {
    const expandable = expand_fields.map((str) => str.replace(/\s/g, '')).join(',');
    const expandable_fields = expandable ? { $expand: `${expandable}` } : {};
    let parameters = { ...params, ...expandable_fields };
    if (parameters[target_id_parameter] === undefined && pass_id) { parameters[target_id_parameter] = pass_id; }
    if (use_search_box && searchField?.trim() !== '') { parameters = { ...parameters, [search_box_parameter]: searchField?.trim() }; }
    if (!use_mapping) {
      if (fingerprint_id && !parameters.metadata_id) { parameters.metadata_id = fingerprint_id; }
    }
    return parameters;
  }, [expand_fields, params, target_id_parameter, pass_id, use_search_box, searchField, use_mapping, search_box_parameter, fingerprint_id]);

  const fetchDataFromApi = useCallback(() => {
    const sendParams = prepareRequestParams();
    if (!use_mapping && array.length > 0) {
      sendParams[search_button_csv_mapping_to_parameter] = array.join(',');
    }
    if (target_url.includes('/catalog_items')) {
      sendParams.status = 'completed';
      sendParams.$top = 100;
      sendParams.$orderby = 'createddate desc';
    }
    if (sendParams['catalog[in]']) {
      const catalogList = sendParams['catalog[in]'].split(',');
      const chunkCatalogList = chunk(catalogList, 50);
      Promise.map(chunkCatalogList, (list) => {
        sendParams['catalog[in]'] = list.join(',');
        return sendApiRequest(target_url, method, sendParams, 'bearer');
      }, { concurrency: 3 }).then((promises) => {
        const dataList = [];
        const temporaryHeader = {};
        promises.forEach(({ data }) => {
          dataList.push(...data);
        });
        if (use_mapping) { fetchMappedData(mapping_params, dataList); return; }
        temporaryHeader['content-range'] = `${dataList.length}/${dataList.length}`;
        setRowAndDataCount(dataList, temporaryHeader, mapping_display_attributes);
      }).catch((err) => {
        handleErrorResponse(err, setAuth);
      });
    } else {
      sendApiRequest(target_url, method, sendParams, 'bearer')
        .then(({ data, headers }) => {
          if (use_mapping) { fetchMappedData(mapping_params, data); return; }
          setRowAndDataCount(data, headers, display_attributes);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [array, chunk, display_attributes, fetchMappedData, mapping_display_attributes, mapping_params, method, prepareRequestParams, search_button_csv_mapping_to_parameter, setAuth, setRowAndDataCount, target_url, use_mapping]);

  const onClickSort = useCallback((headerName) => {
    setLoading(true);
    setSortBy(headerName);
    setSortDirection((previousSortDirection) => (previousSortDirection === 'asc' ? 'desc' : 'asc'));
  }, [setLoading]);

  const renderSystemOrderComponent = useCallback((datum, attribute) => (
    <TextInput
      size="small"
      type="number"
      value={get(datum, attribute.name)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    />
  ), []);

  useEffect(() => {
    if (target_url && pass_id) {
      fetchDataFromApi();
    }
  }, [target_url, pass_id, fetchDataFromApi]);

  const localizedLabels = useMemo(() => ({
    add_button_label: tryGetLocalizedText(add_button_label, language),
    delete_button_label: tryGetLocalizedText(delete_button_label, language),
    search_button_label: tryGetLocalizedText(search_button_label, language),
    search_box_placeholder: tryGetLocalizedText(search_box_placeholder, language),
    select_box_label: tryGetLocalizedText(select_box_label, language),
  }), [add_button_label, delete_button_label, search_button_label, search_box_placeholder, select_box_label, language]);

  const preparedSelectOptions = useMemo(() => {
    if (select_box_options) {
      return select_box_options.map((option) => ({ value: option.value, label: option.label }));
    }
    return [];
  }, [select_box_options]);

  const displayAttributes = useMemo(() => {
    const attributesArray = use_mapping ? mapping_display_attributes : display_attributes;
    if ((attributesArray || []).length > 0) {
      const cols = (attributesArray || []).map((attr) => {
        const { name, data_type } = attr;
        const propertyNamesArray = name.split('.');
        if (propertyNamesArray.length > 1) {
          return {
            name: propertyNamesArray[propertyNamesArray.length - 1],
            nested: propertyNamesArray[0],
            data_type: data_type || 4,
          };
        }
        const prep = { name, data_type: data_type || data_type || (['lastmoddate', 'createddate'].includes(name) ? null : 4) };
        return prep;
      });
      if (selectFields.length > 0) {
        const addable_columns = selectFields.map((field) => {
          const selectOption = select_box_options.find((option) => option.value === field);
          if (selectOption) {
            return {
              name: selectOption.value,
              label: selectOption.label,
              nested: selectOption.nested,
              data_type: selectOption.data_type || 4,
            };
          }
          return null;
        }).filter(Boolean);
        const mergedCols = [...cols, ...addable_columns];
        return mergedCols;
      }
      return cols;
    }
  }, [display_attributes, mapping_display_attributes, selectFields, select_box_options, use_mapping]);

  const columns = useMemo(() => {
    if (!displayAttributes || displayAttributes.length === 0) {
      return [];
    }
    return displayAttributes.map((attribute) => {
      const { name, data_type } = attribute;
      let dataFormatter;
      switch (data_type) {
        case 1:
          dataFormatter = (v) => (v ? 'Yes' : 'No');
          break;
        case 4:
          dataFormatter = (v) => ((v || '').length > 50 ? `${v.substring(0, 50)}...` : v);
          break;
        case 8:
          dataFormatter = (v) => JSON.stringify(v);
          break;
        default:
          if (['lastmoddate', 'createddate'].includes(name)) {
            dataFormatter = (v) => datetimeFormatter(v, DATE_FORMAT_DATE_TIME_DEFAULT);
          }
          break;
      }
      return {
        field: name,
        ...(name === DYNAMIC_TABLE_ATTRIBUTE_SYSTEMM_ORDER ? {
          label: <NumbersIcon fontSize="small" />,
          renderContent: (datum) => renderSystemOrderComponent(datum, attribute),
          width: '0',
        } : {}),
        ...(dataFormatter ? {
          formatter: dataFormatter,
        } : {}),
        ...(columns_config && columns_config[name] ? columns_config[name] : {}),
      };
    });
  }, [displayAttributes, columns_config, renderSystemOrderComponent]);

  const parsedRowData = useMemo(() => rowData.map((rowDatum) => {
    const { ...restDatum } = rowDatum;
    const parsedRowDatum = displayAttributes.reduce((result, attribute) => {
      const { name, nested } = attribute;
      if (nested) {
        const nestedValue = get(rowDatum, nested);
        return { ...result, [name]: nestedValue ? get(nestedValue, name) : '' };
      }
      return { ...result, [name]: get(rowDatum, name) };
    }, {});
    setLoading(false);
    const row = {
      ...parsedRowDatum,
      ...restDatum,
    };
    if (fingerprint_id && row.metadata_id !== fingerprint_id) {
      return null;
    }
    return { ...parsedRowDatum, ...restDatum };
  }).filter(Boolean), [rowData, displayAttributes, setLoading, fingerprint_id]);

  const importCsv = () => {
    const importButton = document.getElementById('csv_import_file');
    importButton.click();
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSearchButtonClick = useCallback(() => {
    setCurrentPage(0);
    importCsv();
  }, []);

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');
    const arr = csvRows.map((i) => {
      const values = i.split(',');
      if (values.length > 0) {
        return values[0];
      }
      throw new Error('CSV file is invalid. Please check the rows.');
    });
    setArray(arr.filter((el) => {
      return el !== '' && el !== undefined && el !== null;
    }));
  };

  useEffect(() => {
    if (file) {
      fileReader.onload = (event) => {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  }, [file, fileReader]);

  useEffect(() => {
    if (array.length > 0) {
      fetchDataFromApi();
    }
  }, [array, fetchDataFromApi, setAuth]);

  const handleAddButtonClick = useCallback(() => {
    navigate(add_button_path || '/');
  }, [navigate, add_button_path]);

  const handleDeleteButtonClick = useCallback(() => {
    navigate(delete_button_path || '/');
  }, [delete_button_path, navigate]);

  const handleBatchSearchClear = useCallback(() => {
    setArray([]);
    setFile(null);
    fetchDataFromApi();
  }, [fetchDataFromApi]);

  return (
    <MKBox>
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      />
      <Grid container justifyContent="center" alignItems="center" marginBottom="1rem" spacing={1}>
        <Grid item xs={2.4}>
          { use_add_button && (
          <Button
            variant="text"
            size="medium"
            fontSize="16px"
            fullWidth
            onClick={() => { handleAddButtonClick(); }}
            iconOnly={false}
            disabled={false}
            circular={false}
            {...add_button_props}
          >
            {`${localizedLabels?.add_button_label}`}
          </Button>
          )}
        </Grid>
        <Grid item xs={2.4}>
          { use_delete_button && (
          <Button
            variant="text"
            size="medium"
            fontSize="16px"
            fullWidth
            onClick={() => { handleDeleteButtonClick(); }}
            iconOnly={false}
            disabled={false}
            circular={false}
            {...delete_button_props}
          >
            {`${localizedLabels?.delete_button_label}`}
          </Button>
          )}
        </Grid>
        <Grid item xs={2.4}>
          {use_select_component && (
          <MultipleSelect
            items={
              preparedSelectOptions
            }
            values={selectFields}
            onSelectChange={(values) => setSelectFields(values)}
            showSelected={!select_box_label}
            openToTop={false}
            tag={localizedLabels?.select_box_label}
            fontStyles={select_box_font_styles}
            {...select_box_props}
          />
          )}
        </Grid>
        <Grid item xs={use_search_button ? 2.4 : 4.2}>
          {use_search_box && (
          <SearchBox
            inputLabel={localizedLabels?.search_box_placeholder}
            inputStyles={search_box_input_props}
            onChange={(values) => setSearchField(values)}
            value={searchField}
            {...search_box_props}
          />
          )}
        </Grid>
        <Grid item xs={2.4}>
          { array.length === 0 && use_search_button && (
          <Button
            variant="text"
            size="medium"
            fontSize="16px"
            fullWidth
            onClick={() => { handleSearchButtonClick(); }}
            iconOnly={false}
            disabled={false}
            circular={false}
            {...search_button_props}
          >
            <input type="file" id="csv_import_file" accept=".csv" onChange={handleOnChange} hidden="hidden" />
            {`${localizedLabels?.search_button_label}`}
          </Button>
          ) }
          { array.length !== 0 && use_search_button && (
          <Button
            variant="text"
            size="medium"
            fontSize="16px"
            fullWidth
            onClick={() => { handleBatchSearchClear(); }}
            iconOnly={false}
            disabled={false}
            circular={false}
            {...search_button_props}
          >
            {`${lm.labels_clear} ${lm.labels_search_batch} (${array.length} ${lm.labels_items})`}
          </Button>
          ) }
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        data={parsedRowData}
        typeRouting={{}}
        totalCount={totalDataCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
        headerBackgroundColor={header_background_color}
        rowBackgroundColor={row_background_color}
        onClickRow={() => {}}
      />
    </MKBox>
  );
};

AMSFilterSearchTable.propTypes = {
  target_url: PropTypes.string,
  target_id_parameter: PropTypes.string,
  pass_id: PropTypes.string,
  method: PropTypes.string,
  params: PropTypes.object,
  expand_fields: PropTypes.arrayOf(PropTypes.string),
  display_attributes: PropTypes.arrayOf(PropTypes.Object),
  columns_config: PropTypes.object,
  type_routing: PropTypes.object,
  disable_pagination: PropTypes.bool,

  use_add_button: PropTypes.bool,
  add_button_path: PropTypes.string,
  add_button_label: PropTypes.string,
  add_button_props: PropTypes.object,

  use_delete_button: PropTypes.bool,
  delete_button_path: PropTypes.string,
  delete_button_label: PropTypes.string,
  delete_button_props: PropTypes.object,

  use_search_button: PropTypes.bool,
  search_button_label: PropTypes.string,
  search_button_props: PropTypes.object,

  use_search_box: PropTypes.bool,
  search_box_placeholder: PropTypes.string,
  search_box_props: PropTypes.object,
  search_box_input_props: PropTypes.object,
  search_box_parameter: PropTypes.string,
  search_button_csv_mapping_to_parameter: PropTypes.string,

  use_select_component: PropTypes.bool,
  select_box_label: PropTypes.string,
  select_box_props: PropTypes.object,
  select_box_parameter: PropTypes.string,
  select_box_font_styles: PropTypes.object,
  select_box_options: PropTypes.object,

  use_mapping: PropTypes.bool,
  mapping_params: PropTypes.object,
  map_target_parameter: PropTypes.string,
  mapping_url: PropTypes.string,
  map_by: PropTypes.string,
  mapping_display_attributes: PropTypes.arrayOf(PropTypes.Object),

  fingerprint_id: PropTypes.string,

  setLoading: PropTypes.func,

  header_background_color: PropTypes.string,
  row_background_color: PropTypes.string,
};

export default withLoading(AMSFilterSearchTable);
