import ButtonApiCall from 'components/ButtonApiCall';
import { useAuth } from 'contexts/auth';
import { useCallback, useEffect, useState } from 'react';
import { sendApiRequest } from 'api/general';
import { handleErrorResponse } from 'utils/general';
import { getDynamicTableRows, updateDynamicTableRow } from 'api/sections';
import { getAttributes } from 'api/collection_definitions';
import { getDynamicData } from 'utils/sections';

const API_KEY = 'AIzaSyC0Lu8QynU9Jhrug28l0-XphN58UFgDHIw';
const GOOGLE_TRANSLATE_API = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
const LANGUAGES_DYNAMIC_TABLE_ID = '73c6d4a4-4014-4df9-9f9a-f82902418eef';

const GoogleTranslateButtonSection = ({
  ...props
}) => {
  const { setAuth } = useAuth();
  const [languages, setLanguages] = useState({});

  const getLanguageAttributes = useCallback(() => {
    const param = {
      collection_definition: LANGUAGES_DYNAMIC_TABLE_ID,
    };
    return getAttributes(param)
      .then(({ data }) => {
        return data;
      }).catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const getLanguages = useCallback(() => {
    return getLanguageAttributes()
      .then((attributes) => {
        return getDynamicTableRows(LANGUAGES_DYNAMIC_TABLE_ID)
          .then(({ data }) => {
            const languagesToSort = data.map((language) => getDynamicData(language, attributes));
            const sortedPriorityLanguages = languagesToSort.sort((a, b) => a.priority - b.priority);
            const languageLabelMap = sortedPriorityLanguages.reduce((result, language) => {
              const { value, google_label } = language;
              const map = {};
              map[value] = google_label;
              return { ...result, ...map };
            }, []);
            setLanguages(languageLabelMap);
          }).catch((err) => {
            handleErrorResponse(err, setAuth);
          });
      }).catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [getLanguageAttributes, setAuth]);

  useEffect(() => {
    getLanguages();
  }, [getLanguages]);

  const sendTranslateApiRequest = useCallback((text, sourceLanguage, targetGoogleLanguange) => {
    if (!text || !targetGoogleLanguange) {
      return Promise.resolve();
    }
    const params = {
      q: text,
      target: targetGoogleLanguange,
      source: sourceLanguage || 'en',
    };

    return sendApiRequest(GOOGLE_TRANSLATE_API, 'post', params, null)
      .then(({ data }) => {
        const { translatedText } = data.data.translations[0];
        return {
          language: targetGoogleLanguange,
          text: translatedText,
        };
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const callApi = useCallback(() => {
    const params = {
      'name[like]': '%use_locales',
      '$expand': 'collection_definition/attributes',
    };
    const collectionDefinitionAttributeMap = {};
    return getAttributes(params).then(({ data }) => {
      if (data.length > 0) {
        const collectionDefinitionIds = data.map((datum) => {
          const collectionDefinitionId = datum.collection_definition.collection_definition_id;
          collectionDefinitionAttributeMap[collectionDefinitionId] = datum.collection_definition.attributes;
          return collectionDefinitionId;
        });
        if (collectionDefinitionIds.length > 0) {
          Promise.all(collectionDefinitionIds.map((collectionDefinitionId) => {
            return getDynamicTableRows(collectionDefinitionId).then((dynamicObjs) => {
              return dynamicObjs.data;
            });
          })).then((promises) => {
            const allDynamicObj = [];
            promises.forEach((dynamicObjs, index) => {
              if (dynamicObjs.length > 0) {
                dynamicObjs.forEach((dynamicObj) => {
                  const formattedDynamicObj = { ...dynamicObj };
                  formattedDynamicObj.collection_definition_id = collectionDefinitionIds[index];
                  allDynamicObj.push(formattedDynamicObj);
                });
              }
            });
            // const allAttributesParam = {
            //   'collection_definition[in]': collectionDefinitionIds.join(),
            // };
            // getAttributes(allAttributesParam).then(({ data: allAttributes }) => {
            allDynamicObj.forEach((dynamicObj) => {
              const attributes = collectionDefinitionAttributeMap[dynamicObj.collection_definition_id];
              const dynamicData = getDynamicData(dynamicObj, attributes);
              Object.keys(dynamicData).forEach((key) => {
                const value = dynamicData[key];
                if (key.includes('.use_locales') && value) {
                  const targetKey = key.split('.use_locales')[0];
                  const targetValue = dynamicData[targetKey];
                  const valueObj = JSON.parse(targetValue);

                  let sourceLanguage;
                  let sourceGoogleLabel;
                  let sourceText;
                  const languagesToTranslate = [];
                  // eslint-disable-next-line no-restricted-syntax
                  for (const language of Object.keys(languages)) {
                    if (valueObj[language] && !sourceLanguage && !sourceGoogleLabel) {
                      sourceLanguage = language;
                      sourceGoogleLabel = languages[language];
                      sourceText = valueObj[language];
                    } else {
                      // } else if (!valueObj[language]) {
                      languagesToTranslate.push(language);
                    }
                  }

                  const promiseArray = [];
                  languagesToTranslate.forEach((language) => {
                    promiseArray.push(sendTranslateApiRequest(sourceText, sourceLanguage, languages[language]));
                  });

                  return Promise.all(promiseArray).then((translatedObjects) => {
                    translatedObjects.forEach((obj) => {
                      const { text, language: targetLanguage } = obj;
                      const translatedLanguage = Object.keys(languages).find((language) => languages[language] === targetLanguage);
                      if (translatedLanguage) {
                        const splitStr = text.toLowerCase().split(' ');
                        const formattedStr = splitStr.map((str) => str.charAt(0).toUpperCase() + str.substring(1));
                        valueObj[translatedLanguage] = formattedStr.join(' ');
                      }
                    });
                    const targetCollectionDefinitionId = dynamicObj.collection_definition_id;
                    const targetDynamicObjId = dynamicObj.id;
                    const targetAttribute = collectionDefinitionAttributeMap[targetCollectionDefinitionId].find((attribute) => attribute.name === targetKey);
                    const targetAttributeId = targetAttribute.attribute_id;
                    const rawDynamicData = JSON.parse(dynamicObj.json_short_data);
                    rawDynamicData[targetAttributeId] = JSON.stringify(valueObj);
                    const updateBody = {
                      json_short_data: JSON.stringify(rawDynamicData),
                    };
                    return updateDynamicTableRow(targetCollectionDefinitionId, targetDynamicObjId, updateBody);
                  });
                }
              });
            });
          });
        }
      }
    });
  }, [languages, sendTranslateApiRequest]);

  return (
    <ButtonApiCall
      onClick={callApi}
      {...props}
    >
      Translate
    </ButtonApiCall>
  );
};

GoogleTranslateButtonSection.propTypes = {};

export default GoogleTranslateButtonSection;
