import PropTypes from 'prop-types';
import MKTypography from 'components/MaterialKit/MKTypography';
import { useCallback, useEffect, useState } from 'react';
import { getDynamicTableRow } from 'api/sections';
import get from 'lodash/get';
import { parseJSON } from 'utils/general';

const AMSRefreshingTextSection = ({ collection_definition_id, target_attribute, row, variant, align, color, font_size, font_weight, is_rich_text, interval, stop_check, editable, onPressEdit, ...props }) => {
  const [text, setText] = useState('fetching status...');
  const [refresh, setRefresh] = useState(null);

  const fetchRowStatus = useCallback(() => {
    if (!collection_definition_id || !row || !target_attribute) {
      return;
    }
    const params = {};
    return getDynamicTableRow(collection_definition_id, row, params)
      .then(({ data }) => {
        const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
        const jsonValue = parseJSON(jsonStrValue, {});
        setText(jsonValue[target_attribute]);
        console.log('Fetched status: ', jsonValue[target_attribute]);
        return data;
      });
  }, [collection_definition_id, row, target_attribute]);

  useEffect(() => {
    const id = setInterval(() => {
      fetchRowStatus();
    }, interval);
    setRefresh(id);
    return () => clearInterval(id);
  }, [interval, row, fetchRowStatus]);

  useEffect(() => {
    fetchRowStatus();
  }, [fetchRowStatus]);

  useEffect(() => {
    if (text === stop_check) {
      console.log('Status is Completed. Clearing interval.');
      clearInterval(refresh);
    }
  }, [text, stop_check, refresh]);

  const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
  return (
    <MKTypography
      variant={variant}
      textAlign={align}
      color={color}
      fontSize={formattedFontSize}
      fontWeight={font_weight}
      {...(is_rich_text ? {
        dangerouslySetInnerHTML: {
          __html: text || '',
        },
      } : {
        children: text || '',
      })}
      {...props}
    />
  );
};

AMSRefreshingTextSection.propTypes = {
  collection_definition_id: PropTypes.string,
  target_attribute: PropTypes.string,
  row: PropTypes.string,
  variant: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  font_weight: PropTypes.string,
  is_rich_text: PropTypes.bool,
  interval: PropTypes.number,
  stop_check: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default AMSRefreshingTextSection;
