/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import MKTypography from 'components/MaterialKit/MKTypography';
import { datetimeFormatter } from 'utils/datetime';
import { tryGetLocalizedText } from 'utils/locales';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getDynamicTableRow, getUserObjectCache } from 'api/sections';
import { getAttribute, getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicData } from 'utils/sections';
import { validate } from 'uuid';
import { isUuid } from 'utils/general';

const TargetAttributeNameValueSection = ({ text, variant, align, color, font_size, font_weight, is_rich_text, is_unix_timestamp, editable, onPressEdit, current_collection_definition_id, current_attribute_id, ...props }) => {
  const { i18n: { language } } = useTranslation();
  const [updatedText, setUpdatedText] = useState();
  const targetAttributeId = props['text.target_attribute_id'];
  const targetCollectionDefinitionId = props['text.target_collection_definition_id'];

  useEffect(() => {
    if (targetAttributeId && targetCollectionDefinitionId && current_attribute_id && current_collection_definition_id) {
      Promise.resolve(getAttribute(targetAttributeId)).then(({ data: targetAttribute }) => {
        const targetAttributeName = targetAttribute.name;
        return getAttribute(current_attribute_id).then(({ data: currentAttribute }) => {
          const currentAttributeName = currentAttribute.name;
          return getUserObjectCache(current_collection_definition_id).then(({ data: objectCache }) => {
            const attributeValue = objectCache[currentAttributeName];
            const isUUID = isUuid(attributeValue);
            if (!isUUID) {
              return setUpdatedText(attributeValue);
            }
            return getCollectionDefinition(targetCollectionDefinitionId, { $expand: 'attributes' }).then(({ data: collectionDefinition }) => {
              const { attributes } = collectionDefinition;
              return getDynamicTableRow(targetCollectionDefinitionId, objectCache[currentAttributeName]).then(({ data: dynamicObj }) => {
                const dynamicData = getDynamicData(dynamicObj, attributes);
                setUpdatedText(dynamicData[targetAttribute[targetAttributeName]]);
              });
            });
          });
        });
      });
    }
  }, [current_attribute_id, current_collection_definition_id, targetAttributeId, targetCollectionDefinitionId]);

  if (updatedText) {
    const textContent = tryGetLocalizedText(updatedText, language);
    const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
    const formattedText = is_unix_timestamp ? datetimeFormatter(Number.isNaN(Number(textContent)) ? textContent : Number(textContent), 'YYYY-MM-DD HH:mm:ss') : textContent || '';
    return (
      <MKTypography
        variant={variant}
        textAlign={align}
        color={color}
        fontSize={formattedFontSize}
        fontWeight={font_weight}
        {...(is_rich_text ? {
          dangerouslySetInnerHTML: {
            __html: is_unix_timestamp ? formattedText : textContent || '',
          },
        } : {
          children: is_unix_timestamp ? formattedText : textContent || '',
        })}
        {...props}
      />
    );
  }
};

TargetAttributeNameValueSection.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  font_weight: PropTypes.string,
  is_rich_text: PropTypes.bool,
  is_unix_timestamp: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
  current_collection_definition_id: PropTypes.string,
  current_attribute_id: PropTypes.string,
};

export default TargetAttributeNameValueSection;
