import PropTypes from 'prop-types';
import SearchBox from 'components/SearchBox';

const SearchBoxSection = ({ label, input_box_props, selected_params_name, ...props }) => {
  return (
    <SearchBox
      inputLabel={label}
      inputStyles={input_box_props}
      searchParamsName={selected_params_name}
      {...props}
    />
  );
};

SearchBoxSection.propTypes = {
  label: PropTypes.string,
  input_box_props: PropTypes.object,
  selected_params_name: PropTypes.string,
};

SearchBoxSection.defaultProps = {
  label: '',
  input_box_props: {},
  selected_params_name: 'search',
};

export default SearchBoxSection;
