import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { isValidEmail } from 'utils/form';
import { useTranslation } from 'react-i18next';
import { getLocaleMap } from 'utils/locales';
import { useEffect, useState } from 'react';

const ForgetPasswordForm = ({ onForgetPassword,
  onClickBack,
  initialValues,
  submitButtonColor,
  submitButtonTitle,
  submitTextColor,
  backButtonColor,
  backButtonTitle,
  backTextColor,
  emailPlaceholder,
  emailLabel,
  ...props }) => {
  const { t } = useTranslation();
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_forgot_password_step_1', 'labels_forgot_password_step_1_description', 'labels_email', 'back_button', 'submit_button', 'errors_required', 'errors_invalid_email'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const getFormValidation = (values) => {
    const { email } = values;
    const errors = {};
    if (!email) {
      errors.email = lm.errors_required;
    } else if (!isValidEmail(email)) {
      errors.email = lm.errors_invalid_email;
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onForgetPassword}
      validate={getFormValidation}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched, setFieldValue }) => {
        return (
          <MKBox
            component="form"
            role="form"
            onSubmit={handleSubmit}
            style={{
              padding: '0px',
              fontSize: '16px',
            }}
          >
            <Grid mb="30px">
              <MKTypography color="black" fontSize="18px">
                {lm.labels_forgot_password_step_1}
              </MKTypography>
            </Grid>
            <Grid mb="30px">
              <MKTypography color="black" fontSize="16px">
                {lm.labels_forgot_password_step_1_description}
              </MKTypography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  {lm.labels_email}
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8} alignItems="center">
                <InputField
                  name="email"
                  value={values.email || ''}
                  type="text"
                  label={emailLabel || lm.labels_email}
                  placeholder={emailPlaceholder || lm.labels_email}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.display_name && !!errors.display_name}
                />
                {touched.email && !!errors.email && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.email}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <Grid container mt="20vh" justifyContent="space-between" color="red">
              <Button onClick={onClickBack} color={backButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={backTextColor}>
                  {backButtonTitle || lm.back_button}
                </MKTypography>
              </Button>
              <Button type="submit" color={submitButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={submitTextColor}>
                  {submitButtonTitle || lm.submit_button}
                </MKTypography>
              </Button>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

ForgetPasswordForm.propTypes = {
  onForgetPassword: PropTypes.func,
  onClickBack: PropTypes.func,
  initialValues: PropTypes.object,
  submitButtonColor: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  submitTextColor: PropTypes.string,
  backButtonColor: PropTypes.string,
  backButtonTitle: PropTypes.string,
  backTextColor: PropTypes.string,
  emailLabel: PropTypes.string,
  emailPlaceholder: PropTypes.string,
};

ForgetPasswordForm.defaultProps = {
  onForgetPassword: () => {},
  onClickBack: () => {},
  initialValues: {},
};

export default ForgetPasswordForm;
