import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUser, updateUser, createUser, getUserRoles } from 'api/users';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { getErrorMessage, handleErrorResponse } from 'utils/general';
import { withLoading } from 'utils/hoc';
import { useAuth } from 'contexts/auth';
import { getLocaleMap } from 'utils/locales';
import EditUserForm from './EditUserForm';

const ResourceNotFound = () => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_resource_not_found'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4>
          {lm.labels_resource_not_found}
        </h4>
      </Container>
    </MKBox>
  );
};

const EditUserSection = ({ setLoading }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_edit', 'labels_create', 'labels_user'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [requestError, setRequestError] = useState(false);
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userId = searchParams.get('username');

  const fetchUserRolesFromApi = useCallback(() => {
    return getUserRoles()
      .then(({ data }) => {
        setUserRoles(data);
      })
      .catch((error) => {
        handleErrorResponse(error, setAuth);
      });
  }, [setAuth]);

  const fetchUserFromApi = useCallback(() => {
    if (!userId) {
      return Promise.resolve();
    }
    setLoading(true);
    return getUser(userId)
      .then(({ data }) => {
        setUser(data);
      })
      .catch((error) => {
        setRequestError(true);
        handleErrorResponse(error, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, setLoading, setAuth]);

  const updateUserToApi = useCallback((values, { setFieldError }) => {
    if (!userId) {
      return Promise.resolve();
    }
    const updateBody = {
      display_name: values.display_name,
      role: values.role,
      // email: values.email,
      // test_user: values.test_user,
    };
    setLoading(true);
    return updateUser(userId, updateBody)
      .then(({ data }) => {
        setUser(data);
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, setLoading, navigate]);

  const createUserToApi = useCallback((values, { setFieldError }) => {
    const createBody = {
      display_name: values.display_name,
      username: values.email,
      email: values.email,
      password: values.password,
      role: values.role,
    };
    setLoading(true);
    return createUser(createBody)
      .then(({ data }) => {
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, navigate]);

  useEffect(() => {
    fetchUserFromApi();
  }, [fetchUserFromApi]);

  useEffect(() => {
    fetchUserRolesFromApi();
  }, [fetchUserRolesFromApi]);

  if (requestError) {
    return <ResourceNotFound />;
  }

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: 10 }}>
          {`${userId ? lm.labels_edit : lm.labels_create} ${lm.labels_user}`}
        </h4>
        <EditUserForm
          user={user}
          onSave={userId ? updateUserToApi : createUserToApi}
          roles={userRoles}
        />
      </Container>
    </MKBox>
  );
};

EditUserSection.propTypes = {
  setLoading: PropTypes.func,
};

export default withLoading(EditUserSection);
