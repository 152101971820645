import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';
import DataTable from 'components/DataTable';
import { handleErrorResponse } from 'utils/general';
import { deleteDatum, getData } from 'api/general';
import { useAuth } from 'contexts/auth';
import { withLoading } from 'utils/hoc';

const FetchingTableSection = ({
  columns,
  page_options,
  table_name,
  id_field,
  extra_params,
  add_data_path,
  edit_data_path,
  addable,
  editable,
  deletable,
  add_button_label,
  edit_button_label,
  delete_button_label,
  setLoading,
  action_button_props,
  ...props
}) => {
  const { setAuth } = useAuth();
  const [rowsData, setRowsData] = useState(null);

  const [pageSize, setPageSize] = useState(page_options?.length ? page_options[0] : 10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const navigate = useNavigate();

  const fetchFromApi = useCallback(() => {
    const dataParams = {
      ...extra_params,
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}),
      $skip: currentPage * pageSize,
      $top: pageSize,
    };
    setLoading(true);
    return getData(`/${table_name}`, dataParams, 'bearer')
      .then(({ data, headers }) => {
        const contentRange = get(headers, 'content-range');
        const size = Number(contentRange.split('/')[1]);
        setRowsData(data);
        setTotalRows(size);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [table_name, extra_params, currentPage, pageSize, sortBy, sortDirection, setLoading, setAuth]);

  const deleteFromApi = useCallback((dataId) => {
    setLoading(true);
    return deleteDatum(`/${table_name}`, dataId, 'bearer')
      .then(() => {
        return fetchFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [table_name, fetchFromApi, setLoading, setAuth]);

  const onClickAdd = useCallback(() => {
    if (add_data_path) {
      navigate(add_data_path);
    }
  }, [add_data_path, navigate]);

  const onClickEdit = useCallback((dataId) => {
    if (edit_data_path) {
      navigate(`${edit_data_path}?${id_field}=${dataId}`);
    }
  }, [id_field, edit_data_path, navigate]);

  const onClickSort = useCallback((headerName) => {
    setSortBy(headerName);
    setSortDirection((previousSortDirection) => (previousSortDirection === 'asc' ? 'desc' : 'asc'));
  }, []);

  useEffect(() => {
    fetchFromApi();
  }, [fetchFromApi]);

  return (
    <DataTable
      idField={id_field}
      data={rowsData}
      columns={columns}
      currentPage={currentPage}
      onPageChange={setCurrentPage}
      rowsPerPage={pageSize}
      rowsPerPageOptions={page_options}
      totalCount={totalRows}
      onRowsPerPageChange={setPageSize}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onSort={onClickSort}
      onClickRow={editable ? onClickEdit : null}
      onPressAdd={addable ? onClickAdd : null}
      onPressEdit={editable ? onClickEdit : null}
      onPressDelete={deletable ? deleteFromApi : null}
      addButtonLabel={add_button_label}
      editButtonLabel={edit_button_label}
      deleteButtonLabel={delete_button_label}
      selectable
      actionButtonProps={action_button_props}
      {...props}
    />
  );
};

FetchingTableSection.propTypes = {
  action_button_props: PropTypes.object,
  page_options: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  table_name: PropTypes.string.isRequired,
  id_field: PropTypes.string,
  extra_params: PropTypes.object,
  add_data_path: PropTypes.string,
  edit_data_path: PropTypes.string,
  columns: PropTypes.array.isRequired,
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  add_button_label: PropTypes.string,
  edit_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
  setLoading: PropTypes.func,
};

export default withLoading(FetchingTableSection);
