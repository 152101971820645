import React, { useCallback, useState, useEffect } from 'react';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicTableRows, createDynamicTableRow, updateDynamicTableRow } from 'api/sections';
import { getDynamicData } from 'utils/sections';
import MKBox from 'components/MaterialKit/MKBox';
import Container from '@mui/material/Container';
import MKTypography from 'components/MaterialKit/MKTypography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MKButton from 'components/MaterialKit/MKButton';
import { Switch } from '@mui/material';
import { getLocaleMap } from 'utils/locales';

const LanguagesCollectionId = '73c6d4a4-4014-4df9-9f9a-f82902418eef';

const LocaleManagementPage = () => {
  const { i18n: { changeLanguage, language } } = useTranslation();
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_locale_management', 'labels_select_language', 'labels_components_usable_languages', 'back_button', 'active_button', 'set_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const fetchDynamicRows = useCallback((id, attributes) => {
    if (!id) return;
    return getDynamicTableRows(id)
      .then(({ data }) => {
        const dynamicData = data.map((row) => {
          const dynamic = getDynamicData(row, attributes);
          dynamic.id = row.id;
          return dynamic;
        });
        setRows(dynamicData);
      });
  }, []);

  const fetchCollectionFromApi = useCallback(() => {
    return getCollectionDefinition(LanguagesCollectionId, { $expand: 'attributes' })
      .then(({ data }) => {
        return {
          id: data?.collection_definition_id,
          attributes: data?.attributes,
        };
      })
      .then(({ id, attributes }) => {
        fetchDynamicRows(id, attributes);
      });
  }, [fetchDynamicRows]);

  useEffect(() => {
    fetchCollectionFromApi();
  }, [fetchCollectionFromApi]);

  const toggleUsage = useCallback((id, prev) => {
    return updateDynamicTableRow(LanguagesCollectionId, id, { json_short_data: JSON.stringify({ 'fa3cbedc-7688-4bae-8b5f-e3f880b54ce2': !prev }) })
      .then((resp) => {
        if (resp.status === 200) {
          fetchCollectionFromApi();
          return;
        }
        alert(lm.alerts_update_failed);
      });
  }, [fetchCollectionFromApi, lm.alerts_update_failed]);

  const handleChangeLanguage = (lang) => {
    localStorage.setItem('i18nextLng', lang);
    changeLanguage(lang);
  };

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <MKButton onClick={() => navigate(-1)} size="small" color="primary">
          {lm.back_button}
        </MKButton>
        <MKTypography variant="h3" color="black" sx={{ my: 4 }}>
          {lm.labels_locale_management}
        </MKTypography>
        <MKBox sx={{
          p: 2,
          backgroundColor: 'lightgray',
          borderRadius: '8px',
        }}
        >
          <MKTypography variant="body1" color="black" fontWeight="bold" sx={{ mb: 2 }}>
            {lm.labels_select_language}
          </MKTypography>
          {rows.map((row) => {
            return (
              <MKBox display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" sx={{ mb: 1 }}>
                <MKButton onClick={() => handleChangeLanguage(row.value)} size="small" color={row.value === language ? 'success' : 'secondary'} sx={{ width: '100px' }}>
                  {row.value === language ? lm.active_button : lm.set_button}
                </MKButton>
                <MKTypography sx={{ ml: 1 }}>
                  {row.name}
                </MKTypography>
              </MKBox>
            );
          })}
        </MKBox>
        <MKBox sx={{
          mt: 4,
          p: 2,
          backgroundColor: 'lightgray',
          borderRadius: '8px',
        }}
        >
          <MKTypography variant="body1" color="black" fontWeight="bold">
            {lm.labels_components_usable_languages}
          </MKTypography>

          <MKBox sx={{ mt: 2 }}>
            {rows.map((row) => {
              return (
                <MKBox display="flex" justifyContent="flex-start" alignItems="center">
                  <Switch checked={row.is_used} onChange={() => toggleUsage(row.id, row.is_used)} />
                  <MKTypography>
                    {row.name}
                  </MKTypography>
                </MKBox>
              );
            })}
          </MKBox>

        </MKBox>
      </Container>
    </MKBox>
  );
};

export default LocaleManagementPage;
