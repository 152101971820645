import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { useState, useEffect, useMemo } from 'react';
import { getSolutions } from 'api/solutions';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import NextPlanRoundedIcon from '@mui/icons-material/NextPlanRounded';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import { IconButton, Button, CircularProgress } from '@mui/material';
import { useAuth } from 'contexts/auth';
import { handleErrorResponse } from 'utils/general';
import { useLocation, useNavigate } from 'react-router-dom';
import MKTypography from 'components/MaterialKit/MKTypography';

const DashboardPage = () => {
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState(true);

  const handlePageNavigation = (pagePath) => {
    console.log('pagePath', pagePath);
    const currentPath = location.pathname;
    console.log('currentPath', currentPath);
    const newPath = currentPath.replace('/dashboard', '') + pagePath;
    console.log('newPath', newPath);
    window.location.href = newPath;
  };

  useEffect(() => {
    getSolutions({ $expand: 'apps/pages' })
      .then(({ data }) => {
        setLoading(false);
        setTreeData(data);
        if (data.length > 0) {
          setExpanded([data[0].solution_id]);
        }
      })
      .catch((err) => {
        setLoading(true);
        alert('Error fetching data');
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const NodeIds = useMemo(() => {
    if (!treeData || !Array.isArray(treeData)) return [];
    const appIds = [];
    treeData.forEach((solution) => {
      if (solution.apps && Array.isArray(solution.apps)) {
        appIds.push(solution.solution_id);
        solution.apps.forEach((app) => {
          appIds.push(app.app_id);
        });
      }
    });
    return appIds;
  }, [treeData]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? NodeIds : []));
  };

  function transformDataToTree(pagesDataArray) {
    const paths = pagesDataArray.map((page) => page.path);
    const tree = {};
    paths.forEach((path) => {
      const parts = path.split('/').filter(Boolean);
      let current = tree;
      parts.forEach((part) => {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      });
    });

    return tree;
  }

  const renderAppPages = (node, path = '', appId, pages) => {
    return Object.keys(node).map((nodeName) => {
      const newPath = `${path}/${nodeName}`;
      const isLeaf = Object.keys(node[nodeName]).length === 0;
      const pageId = pages.find((page) => page.path === newPath)?.page_id;
      return (
        <div key={newPath} style={{ display: 'flex' }}>
          <TreeItem nodeId={newPath} label={nodeName} sx={{ alignItems: 'center', flexGrow: 1, m: '4px 4px 4px 4px' }}>
            {!isLeaf && renderAppPages(node[nodeName], newPath, appId, pages)}
          </TreeItem>
          <IconButton sx={{ alignItems: 'start', justifyContent: 'start', padding: 0 }}>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <NextPlanRoundedIcon color="secondary" fontSize="large" onClick={() => handlePageNavigation(newPath)} />
              <SettingsApplicationsIcon color="primary" fontSize="large" onClick={() => handlePageNavigation(`/app/${appId}/page/${[pageId]}`)} />
            </MKBox>
          </IconButton>
        </div>
      );
    });
  };

  const preparePagesAndRender = (app) => {
    const pagesDataArray = app?.pages.map((page) => ({
      path: page.path,
      id: page.id,
    }));
    const pagesTree = transformDataToTree(pagesDataArray);
    return renderAppPages(pagesTree, app.app_url, app.app_id, app.pages);
  };

  const renderTreeItems = (nodes) => {
    return nodes.map((solution) => (
      <TreeItem key={solution.solution_id} nodeId={solution.solution_id} label={solution.display_name}>
        {solution?.apps.map((app) => (
          <MKBox display="flex">
            <TreeItem key={app.app_id} nodeId={app.app_id} label={app.display_name} sx={{ alignItems: 'center', flexGrow: 1, p: '4px 4px 4px 4px' }}>
              {app?.pages && preparePagesAndRender(app)}
            </TreeItem>
            <IconButton sx={{ alignItems: 'start', justifyContent: 'start', padding: 0 }}>
              <MKBox display="flex" justifyContent="center" alignItems="center">
                <NextPlanRoundedIcon color="secondary" fontSize="large" onClick={() => handlePageNavigation(`${app.base_url}`)} />
                <SettingsApplicationsIcon color="primary" fontSize="large" onClick={() => handlePageNavigation(`/app/${app.app_id}`)} />
              </MKBox>
            </IconButton>
          </MKBox>
        ))}
      </TreeItem>
    ));
  };

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <MKTypography variant="h3" marginBottom={4}>
          Dashboard
        </MKTypography>
        <MKBox display="flex" justifyContent="flex-start" sx={{ backgroundColor: '#F1F2F5', borderRadius: '8px', py: 1, px: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)', mb: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => { navigate(-1); }}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            component="label"
            variant="contained"
            color="dark"
            onClick={handleExpandClick}
            startIcon={expanded.length !== 0 ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}
          >
            {expanded.length !== 0 ? 'Collapse' : 'Expand' }
          </Button>
        </MKBox>

        {loading
          ? (
            <MKBox display="flex" justifyContent="center" sx={{ backgroundColor: '#F1F2F5', borderRadius: '8px', py: 1, px: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)', mb: 1 }}>
              <CircularProgress color="secondary" thickness={3} />
            </MKBox>
          ) : (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeToggle={handleToggle}
              expanded={expanded}
              sx={{ flexGrow: 1, bgcolor: 'background.paper', borderRadius: '8px', py: 1, px: 1, boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)' }}
            >
              {treeData && renderTreeItems(treeData)}
            </TreeView>
          )}
      </Container>
    </MKBox>
  );
};

export default DashboardPage;
