import PropTypes, { number } from 'prop-types';
import MKButton from 'components/MaterialKit/MKButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const LanguageButton = ({ options, text, variant, color, size, fontSize, fullWidth, circular, borderRadius, iconOnly, containerProps, ...props }) => {
  const { i18n: { changeLanguage, language } } = useTranslation();
  const [languageOptions, setLanguageOptions] = useState([]);
  useEffect(() => {
    if (options) {
      setLanguageOptions(options.replace(/\s/g, '').split(','));
    }
  }, [options]);

  const handleChangeLanguage = () => {
    const currentLangIndex = languageOptions.findIndex((lang) => lang === language);
    let nextLangIndex;
    if (currentLangIndex === languageOptions.length - 1) {
      nextLangIndex = 0;
    } else {
      nextLangIndex = currentLangIndex + 1;
    }
    const nextLang = languageOptions[nextLangIndex];
    localStorage.setItem('i18nextLng', nextLang);
    changeLanguage(nextLang);
  };

  return (
    <MKButton
      onClick={handleChangeLanguage}
      fullWidth={fullWidth}
      circular={circular}
      size={size}
      color={color}
      variant={variant}
      iconOnly={iconOnly}
      sx={{
        fontSize,
        ...(!circular ? {
          borderRadius,
        } : {}),
        ...containerProps,
      }}
      {...props}
    >
      {text}
    </MKButton>
  );
};

LanguageButton.propTypes = {
  options: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  circular: PropTypes.bool,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconOnly: PropTypes.bool,
  containerProps: PropTypes.object,
};

LanguageButton.defaultProps = {
  options: null,
  text: null,
  variant: 'contained',
  color: 'dark',
  size: 'medium',
  fontSize: 14,
  fullWidth: false,
  circular: false,
  borderRadius: '8px',
  iconOnly: false,
  containerProps: {},
};

export default LanguageButton;
