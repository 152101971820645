import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const Alert = ({ open, title, description, confirmText, cancelText, confirmButtonStlye, cancelButtonStlye, onClickConfirm, onClickCancel, ...props }) => {
  return (
    <Dialog
      open={open}
      {...props}
    >
      <DialogTitle style={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>
        {title}
      </DialogTitle>
      <DialogContent style={{ padding: '1rem' }}>
        {description.map((text) => text)}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', backgroundColor: '#f3f2f2' }}>
        <Button
          onClick={onClickCancel}
          style={{ ...cancelButtonStlye }}
        >
          {cancelText}
        </Button>
        <Button
          autoFocus
          onClick={onClickConfirm}
          style={{ ...confirmButtonStlye }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Alert.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.array,
  description: PropTypes.string,
  confirmText: PropTypes.string,
  confirmButtonStlye: PropTypes.object,
  cancelText: PropTypes.string,
  cancelButtonStlye: PropTypes.object,
  onClickConfirm: PropTypes.func,
  onClickCancel: PropTypes.func,
};

Alert.defaultProps = {
  open: false,
  title: null,
  description: [],
  confirmText: 'Confirm',
  confirmButtonStlye: {},
  cancelText: 'Cancel',
  cancelButtonStlye: {},
  onClickConfirm: () => {},
  onClickCancel: () => {},
};

export default Alert;
