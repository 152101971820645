import PropTypes from 'prop-types';
import ButtonApiCall from 'components/ButtonApiCall';
import { useAuth } from 'contexts/auth';
import { useCallback, useEffect, useState } from 'react';
import { sendApiRequest } from 'api/general';
import { handleErrorResponse } from 'utils/general';
import { useSearchParams } from 'react-router-dom';
import { getUserObjectCache } from 'api/sections';
import Alert from 'components/Alert';
import { Grid } from '@mui/material';
import MKTypography from 'components/MaterialKit/MKTypography';
import { useTranslation } from 'react-i18next';
import { tryGetLocalizedText, getLocaleMap } from 'utils/locales';

const DELETE_FINGER_PRINT_MASTER_ENDPOINT = '/delete_finger_print_master';
const FINGER_PRINT_MASTER_DYNAMIC_TABLE_ID = 'ae2a2f58-99d8-4744-bab5-1249c7d7e293';

const ButtonApiCallSection = ({
  text,
  route,
  method,
  body,
  redirect_on_submit,
  use_search_params,
  search_params = [],
  to,
  href,
  variant,
  color,
  size,
  font_size,
  full_width,
  circular,
  border_radius,
  icon_only,
  disabled,
  editable,
  onPressEdit,
  ...props
}) => {
  const { i18n: { language } } = useTranslation();
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_warning_exlamation', 'labels_this_cannot_be_undone', 'labels_sure_to_disable', 'delete_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const formattedBorderRadius = Number.isNaN(Number(border_radius)) ? border_radius : Number(border_radius);
  const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
  const [apiResponse, setApiResponse] = useState(null);
  const [params, setParams] = useState({});
  const [targetName, setTargetName] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();

  const doApiRequest = useCallback(() => {
    sendApiRequest(route, method, params, 'bearer')
      .then(({ data }) => {
        setApiResponse(data);
        if (route === DELETE_FINGER_PRINT_MASTER_ENDPOINT) {
          window.location.reload();
        }
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [route, method, params, setAuth]);

  const callApi = useCallback(() => {
    const copiedSearchParams = new URLSearchParams(searchParams);
    const tempParams = { ...body };

    if (use_search_params) {
      // If search_params array is not empty, filter valid search params
      if (search_params.length > 0) {
        const validSearchParams = search_params.filter((param) => copiedSearchParams.has(param));
        validSearchParams.forEach((param) => {
          const paramValue = copiedSearchParams.get(param);
          if (paramValue !== null) {
            tempParams[param] = paramValue;
          }
        });
      } else {
        // If search_params array is empty, allow all search params
        copiedSearchParams.forEach((paramValue, param) => {
          tempParams[param] = paramValue;
        });
      }
      setParams({ ...tempParams });
    }

    if (route === DELETE_FINGER_PRINT_MASTER_ENDPOINT) {
      return getUserObjectCache(FINGER_PRINT_MASTER_DYNAMIC_TABLE_ID)
        .then(({ data }) => {
          const fingerprintName = data?.name;
          setTargetName(fingerprintName);
          setOpenDialog(true);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }

    doApiRequest();
  }, [searchParams, body, use_search_params, route, doApiRequest, search_params, setAuth]);

  const onClickCancel = useCallback(() => {
    setOpenDialog(false);
  }, []);

  return (
    <>
      <ButtonApiCall
        onClick={callApi}
        redirectOnSubmit={redirect_on_submit}
        to={to}
        href={href}
        variant={variant}
        color={color}
        size={size}
        fontSize={formattedFontSize}
        fullWidth={full_width}
        circular={circular}
        borderRadius={formattedBorderRadius}
        iconOnly={icon_only}
        disabled={disabled}
        {...props}
      >
        {tryGetLocalizedText(text, language)}
      </ButtonApiCall>
      <Alert
        open={openDialog}
        fullWidth
        title={lm.labels_warning_exlamation}
        confirmText={lm.delete_button}
        cancelButtonStlye={{ backgroundColor: 'white', color: 'black', borderRadius: 0, border: '1px solid lightgrey' }}
        confirmButtonStlye={{ backgroundColor: '#b20000', color: 'white', borderRadius: 0 }}
        onClickConfirm={doApiRequest}
        onClickCancel={onClickCancel}
        description={[
          <MKTypography style={{ fontSize: '1rem' }}>
            {lm.labels_this_cannot_be_undone}
          </MKTypography>,
          <Grid container direction="row">
            <MKTypography style={{ marginRight: '5px', fontSize: '1rem' }}>
              {lm.labels_sure_to_disable}
            </MKTypography>
            <MKTypography style={{ color: '#b241de', fontSize: '1rem' }}>
              {targetName}
            </MKTypography>
          </Grid>,
        ]}
      />
    </>
  );
};

ButtonApiCallSection.propTypes = {
  text: PropTypes.string,
  route: PropTypes.string,
  method: PropTypes.oneOf(['put', 'post', 'get', 'delete', 'patch']),
  body: PropTypes.object,
  redirect_on_submit: PropTypes.bool,
  use_search_params: PropTypes.bool,
  search_params: PropTypes.arrayOf(PropTypes.string),
  to: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  full_width: PropTypes.bool,
  circular: PropTypes.bool,
  border_radius: PropTypes.string,
  icon_only: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default ButtonApiCallSection;
