import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Files from 'react-files';
import Typography from '@mui/material/Typography';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';

import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { uploadAMSFiles } from 'api/ams';
import { withLoading } from 'utils/hoc';

const AMSFileUploaderSection = ({
  add_button_label,
  fingerprint_database_id,
  audio_match_report_id,
  metadata_id,
  endpoint,
  support_multiple_files,
  onUpload,
  accept_file_types,
  setLoading,
  ...props }) => {
  const [currentFiles, setCurrentFiles] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const addFiles = (files) => {
    setCurrentFiles(currentFiles.concat(files));
  };
  const clearFiles = () => {
    currentFiles.length = 0;
    setCurrentFiles([]);
  };
  const startUpload = () => {
    if (!currentFiles.length && !endpoint) {
      return;
    }

    let totalFileSize = 0;
    currentFiles.forEach((file) => {
      const fileSize = file?.size;
      totalFileSize += fileSize;
    });

    const params = new URLSearchParams({
      ...(fingerprint_database_id && { fingerprint_database_id }),
      ...(audio_match_report_id && { audio_match_report_id }),
      ...(metadata_id && { metadata_id }),
      total_file_size: totalFileSize,
    });

    setLoading(true);
    uploadAMSFiles(endpoint, currentFiles, params).then((obj) => {
      if (onUpload) {
        return onUpload();
      }
      if (obj && obj.status === 200 && !obj.data.error) {
        alert('Uploaded Successfully');
      } else {
        alert(obj.data?.error);
      }
    }).catch((err) => {
      handleErrorResponse(err, setAuth);
    });
    navigate(-1);
    alert('Please do not leave / refresh the page before the upload is completed');
  };

  let labelText = 'No files selected.';
  let popupText = labelText;
  const fileCount = currentFiles.length;
  if (fileCount === 1) {
    const fileName = currentFiles[0].name;
    labelText = fileName;
    popupText = fileName;
  } else {
    labelText = `${fileCount} files selected.`;
    const POPUP_MAX_ROW_COUNT = 25;
    const isWithinMaxRowCount = fileCount <= POPUP_MAX_ROW_COUNT;
    const files = isWithinMaxRowCount ? currentFiles : currentFiles.slice(0, POPUP_MAX_ROW_COUNT);
    popupText = files.map((currentFile) => currentFile.file_name).join('\n');
    if (!isWithinMaxRowCount) {
      popupText += '\n...';
    }
  }

  const back_button_props = {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    sx: {
      backgroundImage: 'url(https://ams.joyaethercloud.com/ams-server/contents/files/1712046662671-Action_Button_bg.png)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: '1px solid black',
      minHeight: '50px',
      maxWidth: '150px',
    },
  };

  const start_button_props = {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    sx: {
      backgroundImage: 'url(https://ams.joyaethercloud.com/ams-server/contents/files/1712046662671-Action_Button_bg.png)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: '1px solid black',
      minHeight: '50px',
      maxWidth: '150px',
    },
  };

  const add_files_button_props = {
    alignItems: 'center',
    color: '#fff',
    sx: {
      backgroundImage: 'url(https://ams.joyaethercloud.com/ams-server/contents/files/1712046662671-Action_Button_bg.png)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: '1px solid black',
    },
  };

  const clear_files_button_props = {
    alignItems: 'center',
    color: '#fff',
    sx: {
      backgroundImage: 'url(https://ams.joyaethercloud.com/ams-server/contents/files/1712046662671-Action_Button_bg.png)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: '1px solid black',
    },
  };

  return (
    <MKBox display="flex" flexDirection="column" sx={{ py: '1rem', px: '2rem' }}>
      <MKBox display="flex" justifyContent="space-between">
        <Button
          variant="text"
          fullWidth
          size="medium"
          fontSize="16px"
          iconOnly={false}
          circular={false}
          borderRadius={0}
          onClick={() => navigate(-1)}
          {...back_button_props}
        >
          Back
        </Button>
        <Button
          variant="text"
          fullWidth
          size="medium"
          fontSize="16px"
          onClick={startUpload}
          iconOnly={false}
          circular={false}
          borderRadius={0}
          {...start_button_props}
        >
          Start
        </Button>
      </MKBox>
      <MKBox
        display="flex"
        justifyContent="start"
        alignItems="center"
        sx={{ mt: '2rem' }}
      >
        <Typography variant="body1" fontSize="16px" color="#000">
          Input files
        </Typography>
        <Typography
          title={popupText}
          fontSize="16px"
          color="#000"
          sx={{ ml: '5rem', mr: '2rem' }}
        >
          {labelText}
        </Typography>
        <Files
          multiple={support_multiple_files}
          accepts={accept_file_types}
          clickable
          onChange={addFiles}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Button
            variant="text"
            fullWidth
            size="medium"
            fontSize="16px"
            borderRadius={0}
            {...add_files_button_props}
          >
            {add_button_label || 'Add Files'}
          </Button>
        </Files>
        {currentFiles.length > 0 && (
          <MKBox sx={{ marginLeft: '2rem' }}>
            <Button
              variant="text"
              fullWidth
              size="medium"
              fontSize="16px"
              borderRadius={0}
              {...clear_files_button_props}
              onClick={clearFiles}
            >
              Clear files
            </Button>
          </MKBox>
        )}
      </MKBox>
    </MKBox>
  );
};

AMSFileUploaderSection.propTypes = {
  add_button_label: PropTypes.string,
  fingerprint_database_id: PropTypes.string,
  audio_match_report_id: PropTypes.string,
  metadata_id: PropTypes.string,
  endpoint: PropTypes.string,
  support_multiple_files: PropTypes.bool,
  onUpload: PropTypes.func,
  accept_file_types: PropTypes.arrayOf(PropTypes.string),
  setLoading: PropTypes.func,
};

export default withLoading(AMSFileUploaderSection);
