import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = ({ inputLabel, inputStyles, value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange(event.target.value ? event.target.value : null);
  };

  return (
    <TextField
      type="text"
      value={value}
      placeholder={inputLabel}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ fontSize: '24px' }}>
            <SearchIcon />
          </InputAdornment>
        ),
        style: {
          ...inputStyles,
        },
      }}
      style={{ ...props }}
    />

  );
};

SearchBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputLabel: PropTypes.string,
  inputStyles: PropTypes.object,
};

export default SearchBox;
