import React from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import FetchingTable from 'sections/FetchingTable';
import { usersTableColumns } from './tablecolumns';

const UsersTableSection = ({
  background_color,
  page_options,
  addable,
  editable,
  deletable,
  add_button_label,
  edit_button_label,
  delete_button_label,
  action_button_props,
  header_background_color,
  row_background_color,
  ...props
}) => {
  return (
    <MKBox
      px={{ xs: 0, md: 1 }}
      py={{ xs: 1, md: 2 }}
      bgColor={background_color}
      {...props}
    >
      <FetchingTable
        table_name="users"
        id_field="username"
        page_options={page_options}
        columns={usersTableColumns}
        add_data_path="/users/add"
        edit_data_path="/users/edit"
        addable={addable}
        editable={editable}
        deletable={deletable}
        add_button_label={add_button_label}
        edit_button_label={edit_button_label}
        delete_button_label={delete_button_label}
        action_button_props={action_button_props || null}
        headerBackgroundColor={header_background_color}
        rowBackgroundColor={row_background_color}
      />
    </MKBox>
  );
};

UsersTableSection.propTypes = {
  background_color: PropTypes.string,
  page_options: PropTypes.arrayOf(
    PropTypes.object,
  ),
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  add_button_label: PropTypes.string,
  edit_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
  action_button_props: PropTypes.object,
  header_background_color: PropTypes.object,
  row_background_color: PropTypes.object,
};

export default UsersTableSection;
