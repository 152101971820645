import { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { updateDynamicTableRow, getDynamicTableRows } from 'api/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useTranslation } from 'react-i18next';
import { tryGetLocalizedText, getLocaleMap } from 'utils/locales';
import startCase from 'lodash/startCase';

const VALUE_ATTRIBTUE_ID = 'b0bc96a8-f5ab-45ef-a9f3-ef201cd0729a';

const AMSEditMetadataConfigurationSection = ({ update_success_message, back_button_props, save_button_props, use_select, select_attributes, section, number_only, ...props }) => {
  const { i18n: { language } } = useTranslation();
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_edit', 'cancel_button', 'back_button', 'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [metadataOptions, setMetadataOptions] = useState([]);
  const { setAuth } = useAuth();

  const getFormValidation = (values) => {
    const errors = {};
    if (number_only && values[VALUE_ATTRIBTUE_ID]) {
      const numberToCheck = values[VALUE_ATTRIBTUE_ID];
      const isNum = /^\d+$/.test(numberToCheck);
      if (!isNum) {
        errors[VALUE_ATTRIBTUE_ID] = 'Value must be digits only';
      }
      if (numberToCheck > 100 || numberToCheck < 0) {
        errors[VALUE_ATTRIBTUE_ID] = 'Value must be between 0 and 100';
      }
    }
    return errors;
  };

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = useCallback((values) => {
    if (!values) return;
    const { dyn_t, section_definition } = section;
    const { collection_definition } = section_definition;
    const updateBody = {
      json_short_data: JSON.stringify(values),
    };
    return updateDynamicTableRow(collection_definition?.collection_definition_id, dyn_t, updateBody)
      .then(() => {
        // eslint-disable-next-line no-alert
        alert(update_success_message);
        navigate(-1);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [section, update_success_message, navigate, setAuth]);

  const fetchDynamicTableRowDataFromApi = useCallback((dynamicTableId, fieldAttributeId, filterId, filterOutValues) => {
    if (dynamicTableId) {
      const params = { $expand: 'attributes', $orderby: 'createddate desc' };
      return getDynamicTableRows(dynamicTableId, params)
        .then(({ data }) => {
          const result = data.map((item) => {
            const fieldData = item.json_short_data || item.json_big_data;
            if (fieldData) {
              try {
                const parsedData = JSON.parse(fieldData);
                const value = item.id;
                const label = parsedData[fieldAttributeId];
                if (!filterId) { return { value, label }; }
                const filter = parsedData[filterId];
                return { value, filter, label };
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error parsing JSON:', error);
                return null;
              }
            }
            return null;
          });
          if (filterId) { return result.filter((item) => !filterOutValues.includes(item?.filter)); }
          return result;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [setAuth]);

  const fetchSelectOptions = useCallback((attribute) => {
    if (!attribute.select) {
      return Promise.resolve(attribute);
    }
    let filter;
    let filterOutValues;
    if (attribute.select.filter_out) {
      filter = attribute.select.filter_out.id;
      filterOutValues = attribute.select.filter_out.values;
    }
    return fetchDynamicTableRowDataFromApi(attribute.select.id, attribute.select.field_attribute, filter, filterOutValues)
      .then((options) => { return { options }; })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
        return null;
      });
  }, [fetchDynamicTableRowDataFromApi, setAuth]);

  const formAttributes = useMemo(() => {
    const { section_definition } = section;
    const { collection_definition } = section_definition;
    const { attributes } = collection_definition;
    const resp = (attributes || []).filter(({ name }) => (name || '').match('^value_([0-9]+)$'));
    return resp;
  }, [section]);

  const initialValues = useMemo(() => {
    return (formAttributes || []).reduce((values, attribute) => {
      const newValues = { ...values };
      const attrNum = attribute.name.replace('value_', '');
      newValues[attribute.attribute_id] = props[`value_${attrNum}`];
      return newValues;
    }, {});
  }, [formAttributes, props]);

  useEffect(() => {
    // fetch and set select options for metadata
    if (use_select) {
      fetchSelectOptions(select_attributes)
        .then((options) => { setMetadataOptions(options.options); });
    }
  }, [fetchSelectOptions, select_attributes, use_select]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
      validate={getFormValidation}
      {...props}
    >
      {({ handleChange, setFieldValue, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <MKBox>
              <MKTypography variant="body1" color="#000" size="medium">
                {`${lm.labels_edit} [${props.label_1}]`}
              </MKTypography>
            </MKBox>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Button
                    variant="text"
                    fullWidth
                    size="medium"
                    fontSize="16px"
                    onClick={onClickCancel}
                    iconOnly={false}
                    circular={false}
                    borderRadius={0}
                    {...back_button_props}
                  >
                    {dirty ? lm.cancel_button : lm.back_button }
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    fullWidth
                    size="medium"
                    fontSize="16px"
                    onClick={() => { onSubmit(); }}
                    iconOnly={false}
                    circular={false}
                    disabled={isSubmitting || !dirty}
                    borderRadius={0}
                    {...save_button_props}
                  >
                    {lm.save_button}
                  </Button>
                </MKBox>
              </Grid>
            </Grid>
            {formAttributes.sort(
              (a1, a2) => a1.name.localeCompare(a2.name),
            ).map((attribute) => {
              const attrNum = attribute.name.replace('value_', '');
              return (
                <MKBox pt="2rem">
                  {!!attribute && (
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12} md={3} xl={2}>
                        <MKTypography
                          variant="body1"
                          color="black"
                          fontSize="16px"
                        >
                          {
                            location.state?.optional_note ? `${startCase(props[`label_${attrNum}`])}*:` : `${startCase(props[`label_${attrNum}`])}:`
                          }
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} md={9} xl={10}>
                        {use_select ? (
                          <InputField
                            name={attribute.attribute_id}
                            label={props[`label_${attrNum}`]}
                            value={values[attribute.attribute_id]}
                            type="select"
                            options={metadataOptions}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        ) : (
                          <InputField
                            name={attribute.attribute_id}
                            label={props[`label_${attrNum}`]}
                            value={values[attribute.attribute_id]}
                            type={props[`input_type_${attrNum}`]}
                            button_text="Upload File"
                            max_file_size={props[`max_file_size_${attrNum}`]}
                            accept_file_types={props[`accept_file_type_${attrNum}`]}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        )}
                      </Grid>
                    </Grid>

                  )}
                  {touched[attribute?.attribute_id] && !!errors[attribute?.attribute_id] && (
                    <MKTypography sx={{ justifyContent: 'flex-start' }} variant="caption" color="error">
                      {errors[attribute?.attribute_id]}
                    </MKTypography>
                  )}
                </MKBox>
              );
            })}
            {location.state?.optional_note && (
              <MKBox sx={{ mt: '1rem' }}>
                <MKTypography variant="body1" fontSize="16px" color="blue" fontWeight="bold">
                  {tryGetLocalizedText(location.state?.optional_note, language)}
                </MKTypography>
              </MKBox>
            )}
          </MKBox>
        );
      }}
    </Formik>
  );
};

AMSEditMetadataConfigurationSection.propTypes = {
  update_success_message: PropTypes.string,
  back_button_props: PropTypes.object,
  save_button_props: PropTypes.object,
  label_1: PropTypes.string,
  section: PropTypes.shape({
    dyn_t: PropTypes.string,
    section_definition: PropTypes.shape({
      collection_definition: PropTypes.shape({
        collection_definition_id: PropTypes.string,
        attributes: PropTypes.array,
      }),
    }),
  }),
  use_select: PropTypes.bool,
  select_attributes: PropTypes.object,
  number_only: PropTypes.bool,
};

export default AMSEditMetadataConfigurationSection;
