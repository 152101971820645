import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { handleErrorResponse, isUuid } from 'utils/general';
import { getCollectionDefinition } from 'api/collection_definitions';
import { createDynamicTableRow, getDynamicTableRows, setUserObjectCache } from 'api/sections';
import { getDynamicData } from 'utils/sections';
import { useAuth } from 'contexts/auth';
import { createAMSReport } from 'api/ams';
import CollectionCreator from 'components/CollectionCreator';
import { getOperations } from 'api/operations';
import { getLocaleMap } from 'utils/locales';

const CollectionCreatorSection = ({ use_endpoint, endpoint, collection_definition_id, on_create_attributes, submit_button_props, back_button_props, navigate_to, check_in_progress_fingerprint_master, ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [formAttributes, setFormAttributes] = useState([]);
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['audio_match_report_generation_is_temporary_stopped_due_to_creating_audio_finger_print_master'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {
        $expand: 'attributes',
      };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        }).catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  const onSubmit = useCallback((values) => {
    let promise;
    if (check_in_progress_fingerprint_master) {
      const operationParams = {
        status: 'in_progress',
        function_name: 'add song to db system command',
      };
      promise = getOperations(operationParams).then(({ data: inProgressOperations }) => {
        if (inProgressOperations.length > 0) {
          alert(lm.audio_match_report_generation_is_temporary_stopped_due_to_creating_audio_finger_print_master);
          return true;
        }
        return false;
      });
    }

    return Promise.resolve(promise).then((res) => {
      if (!res) {
        const filteredValues = {};
        Object.keys(values).forEach((key) => {
          if (key !== '3688d492-9dfd-4e8c-bc44-9d63a5c8c794') {
            filteredValues[key] = values[key];
          }
        });

        const createBody = {
          json_short_data: JSON.stringify({ ...filteredValues }),
        };
        return createDynamicTableRow(collection_definition_id, createBody)
          .then(({ data }) => {
            if (!collectionDefinition) {
              return Promise.resolve();
            }
            const dynamicData = getDynamicData(data, collectionDefinition.attributes);
            return Promise.all([
              setUserObjectCache(collectionDefinition.collection_definition_id, data?.id),
              (dynamicData?.redirect_collection_definition_id && dynamicData?.redirect_object_id) ? (
                setUserObjectCache(dynamicData.redirect_collection_definition_id, dynamicData.redirect_object_id)
              ) : null,
            ])
              .then(() => {
                if (use_endpoint && data?.id) {
                  const file = values['3688d492-9dfd-4e8c-bc44-9d63a5c8c794'];
                  const reportBody = {
                    id: data.id,
                    total_file_size: file[0].size,
                    files: file,
                  };
                  createAMSReport(endpoint, reportBody)
                    .catch((err) => {
                      handleErrorResponse(err, setAuth);
                      navigate(-1);
                    });
                }
                navigate(navigate_to || '/');
              })
              .catch((err) => {
                handleErrorResponse(err, setAuth);
              });
          })
          .catch((err) => {
            handleErrorResponse(err, setAuth);
          });
      }
    });
  }, [check_in_progress_fingerprint_master, collectionDefinition, collection_definition_id, endpoint, navigate, navigate_to, setAuth, use_endpoint]);

  const fetchDynamicTableRowDataFromApi = useCallback((dynamicTableId, fieldAttributeId, filterId, filterOutValues) => {
    if (dynamicTableId) {
      const params = {
        $expand: 'attributes',
        $orderby: 'createddate desc',
      };
      return getDynamicTableRows(dynamicTableId, params)
        .then(({ data }) => {
          const result = data.map((item) => {
            const fieldData = item.json_short_data || item.json_big_data;
            if (fieldData) {
              try {
                const parsedData = JSON.parse(fieldData);
                const value = item.id;
                const label = parsedData[fieldAttributeId];
                if (!filterId) { return { value, label }; }
                const filter = parsedData[filterId];
                return { value, filter, label };
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error parsing JSON:', error);
                return null;
              }
            }
            return null;
          });
          if (filterId) { return result.filter((item) => !filterOutValues.includes(item?.filter)); }
          return result;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [setAuth]);

  const fetchSelectOptions = useCallback((attribute) => {
    if (!attribute.select) {
      return Promise.resolve(attribute);
    }
    let filter;
    let filterOutValues;
    if (attribute.select.filter_out) {
      filter = attribute.select.filter_out.id;
      filterOutValues = attribute.select.filter_out.values;
    }
    return fetchDynamicTableRowDataFromApi(attribute.select.id, attribute.select.field_attribute, filter, filterOutValues)
      .then((options) => {
        return { options };
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
        return null;
      });
  }, [fetchDynamicTableRowDataFromApi, setAuth]);

  const validCreateAttributes = useMemo(() => {
    if (!on_create_attributes) {
      return Promise.resolve([]);
    }
    if (!collectionDefinition?.attributes) {
      return Promise.resolve([]);
    }
    return Promise.all(
      (on_create_attributes || []).map(({ attribute, ...rest }) => {
        const matchingAttribute = collectionDefinition?.attributes.find(({ name }) => name === attribute);
        if (matchingAttribute) {
          if (rest.select) {
            return fetchSelectOptions({ attribute, ...rest })
              .then((options) => {
                return {
                  ...matchingAttribute,
                  ...rest,
                  ...options,
                };
              })
              .catch((err) => {
                handleErrorResponse(err, setAuth);
              });
          } return { ...matchingAttribute, ...rest };
        }
        return null;
      }),
    );
  }, [fetchSelectOptions, on_create_attributes, collectionDefinition?.attributes, setAuth]);

  useEffect(() => {
    validCreateAttributes
      .then((attributes) => {
        setFormAttributes(attributes);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [validCreateAttributes, setAuth]);

  if (!formAttributes) {
    return null;
  }

  return (
    <CollectionCreator
      endpoint={endpoint}
      onSubmit={onSubmit}
      formAttributes={formAttributes}
      submitButtonProps={submit_button_props}
      backButtonProps={back_button_props}
      navigateTo={navigate_to}
      {...props}
    />
  );
};

CollectionCreatorSection.propTypes = {
  use_endpoint: PropTypes.bool,
  endpoint: PropTypes.string,
  collection_definition_id: PropTypes.string,
  on_create_attributes: PropTypes.array,
  submit_button_props: PropTypes.object,
  back_button_props: PropTypes.object,
  navigate_to: PropTypes.string,
  check_in_progress_fingerprint_master: PropTypes.bool,
};

export default CollectionCreatorSection;
