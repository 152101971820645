import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import ResetPasswordForm from 'components/ResetPasswordForm';
import { resetPassword } from 'api/auth';
import { getErrorMessage } from 'utils/general';
import { getLocaleMap } from 'utils/locales';

const resetPasswordFormInitialValues = {
  temporary_password: '',
  new_password: '',
  reenter_new_password: '',
};

const ResetPasswordSection = ({
  submit_button_color,
  submit_button_title,
  submit_text_color,
  back_button_color,
  back_button_title,
  back_text_color,
  to,
  ...props }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [lm, setLm] = useState({});

  const onResetPassword = useCallback((values, { setFieldError }) => {
    const { temporary_password, new_password } = values;
    const email = searchParams.get('email');
    if (email) {
      return resetPassword(email, temporary_password, new_password)
        .then(() => {
          navigate(to || '/');
        })
        .catch(() => {
          setFieldError('form', lm.new_password_cannot_be_same_as_old_password);
        });
    }
  }, [searchParams, navigate, to, lm.new_password_cannot_be_same_as_old_password]);

  useEffect(() => {
    getLocaleMap(['new_password_cannot_be_same_as_old_password'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <MKBox py="2rem">
        <ResetPasswordForm
          onResetPassword={onResetPassword}
          onClickBack={onClickBack}
          initialValues={resetPasswordFormInitialValues}
          submitButtonColor={submit_button_color}
          submitButtonTitle={submit_button_title}
          submitTextColor={submit_text_color}
          backButtonColor={back_button_color}
          backButtonTitle={back_button_title}
          backTextColor={back_text_color}
          to={to}
          {...props}
        />
      </MKBox>
    </Container>
  );
};

ResetPasswordSection.propTypes = {
  submit_button_color: PropTypes.string,
  submit_button_title: PropTypes.string,
  submit_text_color: PropTypes.string,
  back_button_color: PropTypes.string,
  back_button_title: PropTypes.string,
  back_text_color: PropTypes.string,
  to: PropTypes.string,
};

ResetPasswordSection.defaultProps = {
  to: null,
};

export default ResetPasswordSection;
