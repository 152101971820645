import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import CustomSelect from './customSelect';

const MultipleSelect = ({ items, tag, openToTop, showSelected, fontStyles, onSelectChange, searchParamsName, initialValues, ...props }) => {
  const handleChange = (values) => {
    onSelectChange(values);
  };

  return (
    <CustomSelect
      options={items}
      onChange={handleChange}
      values={initialValues || []}
      showSelected={showSelected}
      tag={tag}
      openToTop={openToTop}
      fontStyles={fontStyles}
      searchParamsName={searchParamsName}
      {...props}
    />
  );
};

MultipleSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  showSelected: PropTypes.bool,
  onSelectChange: PropTypes.func,
  initialValues: PropTypes.array,
  fontStyles: PropTypes.object,
  tag: PropTypes.string,
  openToTop: PropTypes.bool,
  searchParamsName: PropTypes.string,
};

export default MultipleSelect;
