import { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';
import { useTranslation } from 'react-i18next';
import { getDynamicTableRows } from 'api/sections';
import EditAttributeField from 'components/EditAttributeField';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import { getDynamicData } from 'utils/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getLocaleMap } from 'utils/locales';

const locales_collection_definition_id = '73c6d4a4-4014-4df9-9f9a-f82902418eef';

const EditMenuItemDependencyForm = ({ menuItemDependency, onSave, onCancel, ...props }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap([
      'labels_label',
      'labels_label_props',
      'labels_active_label_props',
      'labels_type',
      'labels_new_tab',
      'labels_path',
      'labels_img_url',
      'labels_active_img_url',
      'labels_img_props',
      'labels_active_img_props',
      'labels_container_props',
      'labels_active_container_props',
      'labels_sequence',
      'labels_disabled',
      'labels_use_locales',
      'labels_true',
      'labels_false',
      'labels_node',
      'labels_item',
      'labels_yes',
      'labels_no',
      'cancel_button',
      'save_button',
    ])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const [rows, setRows] = useState([]);

  const fetchDynamicRows = useCallback((id, localesAttributes) => {
    if (!id) return;
    return getDynamicTableRows(id)
      .then(({ data }) => {
        const dynamicData = data.map((row) => {
          const dynamic = getDynamicData(row, localesAttributes);
          if (!dynamic.is_used) {
            return;
          }
          dynamic.id = row.id;
          return dynamic;
        }).filter(Boolean);
        return dynamicData;
      });
  }, []);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    return getCollectionDefinition(locales_collection_definition_id, { $expand: 'attributes' })
      .then(({ data }) => {
        return {
          collection_definition_id: data?.collection_definition_id,
          attributes: data?.attributes,
        };
      });
  }, []);

  const prepareRows = useCallback(() => {
    fetchCollectionDefinitionFromApi()
      .then((data) => {
        fetchDynamicRows(data.collection_definition_id, data.attributes)
          .then((dynamicData) => {
            setRows(dynamicData);
          });
      });
  }, [fetchCollectionDefinitionFromApi, fetchDynamicRows]);

  useEffect(() => {
    prepareRows();
  }, [prepareRows]);

  const prepareSave = (values, { setFieldError }) => {
    const preparedValues = { ...values };
    const localized_text = {};
    rows.forEach((row) => {
      localized_text[row.value] = values[row.id];
      delete preparedValues[row.id];
      preparedValues.label = JSON.stringify(localized_text);
    });
    onSave(preparedValues, { setFieldError });
  };

  const menuIemTypeOptions = [
    { label: lm.labels_node, value: 1 },
    { label: lm.labels_item, value: 2 },
  ];

  const TFOptions = [
    { label: lm.labels_true, value: true },
    { label: lm.labels_false, value: false },
  ];

  const initialValues = useMemo(() => ({
    label: menuItemDependency?.menu_item?.label || '',
    label_props: menuItemDependency?.menu_item?.label_props || '{}',
    active_label_props: menuItemDependency?.menu_item?.active_label_props || '{}',
    type: menuItemDependency?.menu_item?.type || 2,
    new_tab: menuItemDependency?.menu_item?.new_tab || false,
    path: menuItemDependency?.menu_item?.path || '',
    img_url: menuItemDependency?.menu_item?.img_url || '',
    active_img_url: menuItemDependency?.menu_item?.active_img_url || '',
    img_props: menuItemDependency?.menu_item?.img_props || '{}',
    active_img_props: menuItemDependency?.menu_item?.active_img_props || '{}',
    container_props: menuItemDependency?.menu_item?.container_props || '{}',
    active_container_props: menuItemDependency?.menu_item?.active_container_props || '{}',
    sequence: menuItemDependency?.sequence || '',
    disabled: menuItemDependency?.menu_item?.disabled || false,
    use_locales: false,
  }), [menuItemDependency]);

  return (
    <Formik
      onSubmit={prepareSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={10}>
                <MKInput
                  name="label"
                  label={lm.labels_label}
                  value={!values.use_locales ? values.label : '-'}
                  onChange={handleChange('label')}
                  onBlur={handleBlur('label')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={2}>
                <Select
                  name="label"
                  label="Use locales"
                  value={values.use_locales}
                  onChange={(v) => setFieldValue('use_locales', v)}
                  options={TFOptions}
                />
              </Grid>
              { values.use_locales && rows.map((row) => {
                const att = {
                  attribute_id: row.id,
                  name: row.name,
                  data_type: 4,
                };
                return (
                  <Grid item xs={12}>
                    <MKBox key={row.id} ml={4} display="flex" flexDirection="row" alignItems="center">
                      <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                      <EditAttributeField
                        attribute={att}
                        label={row.name}
                        value={values[row.id]}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </MKBox>
                  </Grid>
                );
              })}
              <Grid container item xs={12}>
                <MKInput
                  name="label_props"
                  label={lm.labels_label_props}
                  value={values.label_props || '{}'}
                  onChange={handleChange('label_props')}
                  onBlur={handleBlur('label_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_label_props"
                  label={lm.labels_active_label_props}
                  value={values.active_label_props || '{}'}
                  onChange={handleChange('active_label_props')}
                  onBlur={handleBlur('active_label_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="img_url"
                  label={lm.labels_img_url}
                  value={values.img_url || ''}
                  onChange={handleChange('img_url')}
                  onBlur={handleBlur('img_url')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="img_props"
                  label={lm.labels_img_props}
                  value={values.img_props || '{}'}
                  onChange={handleChange('img_props')}
                  onBlur={handleBlur('img_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_img_url"
                  label={lm.labels_active_img_url}
                  value={values.active_img_url || ''}
                  onChange={handleChange('active_img_url')}
                  onBlur={handleBlur('active_img_url')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_img_props"
                  label={lm.labels_active_img_props}
                  value={values.active_img_props || '{}'}
                  onChange={handleChange('active_img_props')}
                  onBlur={handleBlur('active_img_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label={lm.labels_type}
                  value={values.type}
                  onChange={(v) => setFieldValue('type', v)}
                  options={menuIemTypeOptions}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKInput
                  name="sequence"
                  label={lm.labels_sequence}
                  type="number"
                  value={values.sequence || ''}
                  onChange={handleChange('sequence')}
                  onBlur={handleBlur('sequence')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="New Tab"
                  value={values.new_tab}
                  onChange={(v) => setFieldValue('new_tab', v)}
                  options={[
                    { label: lm.labels_yes, value: true },
                    { label: lm.labels_no, value: false },
                  ]}
                  invalid={touched.new_tab && !!errors.new_tab}
                />
              </Grid>
              {values.type !== 1 && (
                <Grid item xs={12}>
                  <MKInput
                    name="path"
                    label={lm.labels_path}
                    value={values.path || ''}
                    onChange={handleChange('path')}
                    onBlur={handleBlur('path')}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Select
                  label={lm.labels_disabled}
                  value={values.disabled}
                  onChange={(v) => setFieldValue('disabled', v)}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  invalid={touched.disabled && !!errors.disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="container_props"
                  label={lm.labels_container_props}
                  value={values.container_props || '{}'}
                  onChange={handleChange('container_props')}
                  onBlur={handleBlur('container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="active_container_props"
                  label={lm.labels_active_container_props}
                  value={values.active_container_props || '{}'}
                  onChange={handleChange('active_container_props')}
                  onBlur={handleBlur('active_container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      {lm.cancel_button}
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      disabled={isSubmitting || !dirty}
                      fullWidth
                    >
                      {isSubmitting ? (
                        <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                      ) : lm.save_button}
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditMenuItemDependencyForm.propTypes = {
  menuItemDependency: PropTypes.shape({
    menu_item: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.number,
      new_tab: PropTypes.bool,
      path: PropTypes.string,
      img_url: PropTypes.string,
      active_img_url: PropTypes.string,
      img_props: PropTypes.string,
      active_img_props: PropTypes.string,
      label_props: PropTypes.string,
      active_label_props: PropTypes.string,
      container_props: PropTypes.string,
      active_container_props: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    sequence: PropTypes.number,
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditMenuItemDependencyForm.defaultProps = {
  menuItemDependency: {},
  onSave: () => {},
  onCancel: () => {},
};

export default EditMenuItemDependencyForm;
