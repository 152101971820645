import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MKButton from 'components/MaterialKit/MKButton';

const ButtonApiCall = ({
  onClick,
  redirectOnSubmit,
  to,
  href,
  variant,
  color,
  size,
  fontSize,
  fullWidth,
  circular,
  borderRadius,
  disabled,
  iconOnly,
  sx,
  ...props }) => {
  const navigate = useNavigate();

  const newOnClick = (e) => {
    return Promise.resolve(onClick(e))
      .then(() => {
        if (redirectOnSubmit && to) {
          navigate(to);
        }
      });
  };

  return (
    <MKButton
      onClick={newOnClick}
      href={href}
      fullWidth={fullWidth}
      circular={circular}
      size={size}
      color={color}
      variant={variant}
      iconOnly={iconOnly}
      disabled={disabled}
      sx={{
        fontSize,
        ...(!circular ? {
          borderRadius,
        } : {}),
        ...sx,
      }}
      {...(href ? {
        target: '_blank',
        rel: 'noreferrer noopener',
      } : {})}
      {...props}
    />
  );
};

ButtonApiCall.propTypes = {
  onClick: PropTypes.func,
  redirectOnSubmit: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  circular: PropTypes.bool,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconOnly: PropTypes.bool,
  sx: PropTypes.object,
};

ButtonApiCall.defaultProps = {
  onClick: () => {},
  redirectOnSubmit: false,
  to: null,
  href: null,
  variant: 'contained',
  color: 'dark',
  size: 'medium',
  fontSize: 14,
  fullWidth: false,
  disabled: false,
  circular: false,
  borderRadius: '8px',
  iconOnly: false,
  sx: {},
};

export default ButtonApiCall;
