import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updatePassword } from 'api/users';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { getErrorMessage } from 'utils/general';
import { withLoading } from 'utils/hoc';
import { getLocaleMap } from 'utils/locales';
import SetPasswordForm from './SetPasswordForm';

const SetPasswordSection = ({ setLoading }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_set_password', 'alerts_update_successfully'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userId = searchParams.get('username');

  const updatePasswordToApi = useCallback((values, { setFieldError }) => {
    const body = {
      password: values.password,
    };
    setLoading(true);
    return updatePassword(userId, body)
      .then(() => {
        alert(lm.alerts_update_successfully);
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, userId, lm.alerts_update_successfully, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: 10 }}>
          {lm.labels_set_password}
        </h4>
        <SetPasswordForm
          onSave={updatePasswordToApi}
        />
      </Container>
    </MKBox>
  );
};

SetPasswordSection.propTypes = {
  setLoading: PropTypes.func,
};

export default withLoading(SetPasswordSection);
