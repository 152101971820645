import PropTypes from 'prop-types';
import DatePicker from 'components/DatePicker';

const DatePickerSection = ({ label, type, format, use_unix_format, ...props }) => {
  return (
    <DatePicker
      label={label}
      type={type}
      dateFormat={format}
      useUnixTimestamp={use_unix_format}
      {...props}
    />
  );
};

DatePickerSection.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  format: PropTypes.string,
  use_unix_format: PropTypes.bool,
};

DatePickerSection.defaultProps = {
  label: '',
  type: 'date',
  format: 'YYYY-MM-DD',
  use_unix_format: false,
};

export default DatePickerSection;
