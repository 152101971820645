import PropTypes from 'prop-types';
import MultipleSelect from 'components/MultipleSelect';

const MultipleSelectSection = ({ items, to_top, tag, show_selected, font_props, selected_params_name, ...props }) => {
  return (
    <MultipleSelect
      items={items}
      openToTop={to_top || false}
      tag={tag}
      fontStyles={font_props}
      showSelected={show_selected || false}
      searchParamsName={selected_params_name}
      {...props}
    />
  );
};

MultipleSelectSection.propTypes = {
  items: PropTypes.array,
  to_top: PropTypes.bool,
  show_selected: PropTypes.bool,
  tag: PropTypes.string,
  font_props: PropTypes.object,
  selected_params_name: PropTypes.string,
};

MultipleSelectSection.defaultProps = {
  items: [{}],
  to_top: false,
  show_selected: false,
  tag: '',
  font_props: {},
  selected_params_name: 'selectedValues',
};

export default MultipleSelectSection;
