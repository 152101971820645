import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import { handleErrorResponse } from 'utils/general';
import { getMenus } from 'api/menus';
import { useAuth } from 'contexts/auth';
import { useTranslation } from 'react-i18next';
import { getLocaleMap } from 'utils/locales';

const MenuSection = () => {
  const [lm, setLm] = useState({});
  const [menus, setMenus] = useState([]);
  const navigate = useNavigate();
  const { appId } = useParams();
  const { setAuth } = useAuth();

  useEffect(() => {
    getLocaleMap(['labels_display_name', 'labels_starting_menu_node', 'labels_menus'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const onPressEdit = useCallback((menuId) => {
    navigate(`/app/${appId}/menu/${menuId}`);
  }, [appId, navigate]);

  const fetchMenusFromApi = useCallback(() => {
    const menuParams = {
      $expand: 'menu_node',
    };
    return getMenus(menuParams)
      .then(({ data }) => {
        setMenus(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    fetchMenusFromApi();
  }, [fetchMenusFromApi]);

  const menuTableColumns = useMemo(() => [
    { field: 'display_name', label: lm.labels_display_name },
    {
      field: 'menu_node.label',
      label: lm.labels_starting_menu_node,
    },
  ], [lm.labels_display_name, lm.labels_starting_menu_node]);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">{lm.labels_menus}</MKTypography>
      </MKBox>
      <DataTable
        data={menus}
        columns={menuTableColumns}
        idField="menu_id"
        onPressEdit={onPressEdit}
        paginationDisabled
        selectable
      />
    </MKBox>
  );
};

export default MenuSection;
