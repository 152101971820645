import api from 'api';

export const getMe = (params) => {
  return api.get('/user/me', params, 'bearer');
};

export const getUser = (id, params) => {
  return api.get(`/users/${id}`, params, 'bearer');
};

export const getUsers = (params) => {
  return api.get('/users', params, 'bearer');
};

export const updateUser = (id, body) => {
  return api.put(`/users/${id}`, body, 'bearer');
};

export const createUser = (body) => {
  return api.post('/users', body, 'bearer');
};

export const getUserRoles = (params) => {
  return api.get('/roles', params, 'bearer');
};

export const updatePassword = (id, body) => {
  return api.post(`/update_password/${id}`, body, 'bearer');
};
