import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import BreadcrumbHeader from 'components/BreadcrumbHeader';

const BreadcrumbHeaderSection = ({ routes, separator_size, color, bg_color, variant, language_options, language_text, language_variant, language_color, language_size, language_font_size, language_full_width, language_circular, language_border_radius, language_icon_only, language_container_props, ...props }) => {
  const languageProps = {};
  const breadcrumbHeaderProps = {};
  Object.keys(props).forEach((key) => {
    if (key.startsWith('language')) {
      languageProps[key.replace('language_', '')] = props[key];
    } else {
      breadcrumbHeaderProps[key] = props[key];
    }
  });
  return (
    <MKBox>
      <BreadcrumbHeader
        routes={routes}
        bgColor={bg_color}
        separatorSize={separator_size}
        color={color}
        variant={variant}
        languageOptions={language_options}
        languageText={language_text}
        languageVariant={language_variant}
        languageColor={language_color}
        languageSize={language_size}
        languageFontSize={language_font_size}
        languageFullWidth={language_full_width}
        languageCircular={language_circular}
        languageBorderRadius={language_border_radius}
        languageIconOnly={language_icon_only}
        languageContainerProps={language_container_props}
        languageProps={languageProps}
        {...breadcrumbHeaderProps}
      />
    </MKBox>
  );
};

BreadcrumbHeaderSection.propTypes = {
  bg_color: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  separator_size: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
  language_options: PropTypes.string,
  language_text: PropTypes.string,
  language_variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  language_color: PropTypes.string,
  language_size: PropTypes.oneOf(['small', 'medium', 'large']),
  language_font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language_full_width: PropTypes.bool,
  language_circular: PropTypes.bool,
  language_border_radius: PropTypes.string,
  language_icon_only: PropTypes.bool,
  language_container_props: PropTypes.object,
};

BreadcrumbHeaderSection.defaultProps = {
  bg_color: null,
  color: null,
  variant: null,
  separator_size: null,
  routes: [],
};

export default BreadcrumbHeaderSection;
