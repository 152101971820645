import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const DatePicker = ({ label, type, dateFormat, selectedDate, onDateChange, ...props }) => {
  const internalSelectedDate = useMemo(() => selectedDate || moment(), [selectedDate]);

  const handleDateChange = useCallback((date) => {
    onDateChange(date);
  }, [onDateChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        label={label}
        onChange={handleDateChange}
        value={internalSelectedDate}
        {...props}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  dateFormat: PropTypes.string,
  selectedDate: PropTypes.object, // Moment.js object for the selected date
  onDateChange: PropTypes.func, // Callback function for date change
};

export default DatePicker;
