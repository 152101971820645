import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MKBox from 'components/MaterialKit/MKBox';
import Breadcrumbs from 'components/Breadcrumbs';
import LanguageButton from 'components/LanguageButton';
import HeaderUserInfo from './HeaderUserInfo';

const BreadcrumbHeader = ({ bgColor, routes, separatorSize, color, variant, languageOptions, languageText, languageVariant, languageColor, languageSize, languageFontSize, languageFullWidth, languageCircular, languageBorderRadius, languageIconOnly, languageContainerProps, languageProps, ...props }) => {
  const DEFAULT_MENU_HEADER_HEIGHT = 100;
  const formattedFontSize = Number.isNaN(Number(languageFontSize)) ? languageFontSize : Number(languageFontSize);
  return (
    <MKBox bgColor={bgColor} p={{ xs: 0, md: 2 }} sx={{ height: DEFAULT_MENU_HEADER_HEIGHT }}>
      <MKBox py={1} borderRadius="xl" left={0}>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox ml={1}>
            <LanguageButton
              options={languageOptions}
              text={languageText}
              variant={languageVariant}
              color={languageColor}
              size={languageSize}
              fontSize={formattedFontSize}
              fullWidth={languageFullWidth}
              circular={languageCircular}
              borderRadius={languageBorderRadius}
              iconOnly={languageIconOnly}
              containerProps={languageContainerProps}
              {...languageProps}
            />
            <Breadcrumbs
              routes={routes}
              separator={(
                <ArrowForwardIosIcon
                  fontSize={separatorSize}
                  color={color}
                />
              )}
              labelProps={{
                variant,
                color,
              }}
              {...props}
            />
          </MKBox>
          <MKBox mr={2}>
            <HeaderUserInfo fontColor={color} />
          </MKBox>
        </MKBox>
      </MKBox>
    </MKBox>
  );
};

BreadcrumbHeader.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  separatorSize: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
  languageOptions: PropTypes.string,
  languageText: PropTypes.string,
  languageVariant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  languageColor: PropTypes.string,
  languageSize: PropTypes.oneOf(['small', 'medium', 'large']),
  languageFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  languageFullWidth: PropTypes.bool,
  languageCircular: PropTypes.bool,
  languageBorderRadius: PropTypes.string,
  languageIconOnly: PropTypes.bool,
  languageContainerProps: PropTypes.object,
  languageProps: PropTypes.object,
};

BreadcrumbHeader.defaultProps = {
  bgColor: null,
  color: null,
  separatorSize: null,
  variant: null,
  routes: [],
};

export default BreadcrumbHeader;
