import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import MKTypography from 'components/MaterialKit/MKTypography';
import InputField from 'components/InputField';
import startCase from 'lodash/startCase';
import { useAuth } from 'contexts/auth';
import { getLocaleMap } from 'utils/locales';
import ReportFileUploader from 'sections/AMSUploadFolder';
import { getDynamicTableRows } from 'api/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicData } from 'utils/sections';

const DEFAULT_MATCHING_THRESHOLD_COLLECTION_DEFINITION_ID = '453c3791-1c85-4556-9b8f-dfabb669b9f9';

const CollectionCreator = ({ formAttributes, onSubmit, backButtonProps, submitButtonProps, navigateTo, ...props }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_operator', 'upload_file_button', 'cancel_button', 'back_button', 'create_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [defaultMatchingThreshold, setDefaultMatchingThreshold] = useState(null);

  const initialValues = useMemo(() => {
    return formAttributes.reduce((values, attribute) => {
      const newValues = { ...values };
      newValues[attribute.attribute_id] = values[attribute.attribute_id] || '';
      if (attribute.operator_field) {
        newValues[attribute.attribute_id] = auth.user?.display_name || 'Unknown operator';
      }
      if (attribute.default_matching_threshold && defaultMatchingThreshold) {
        newValues[attribute.attribute_id] = defaultMatchingThreshold;
      }
      return newValues;
    }, {});
  }, [formAttributes, defaultMatchingThreshold, auth.user?.display_name]);

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    getCollectionDefinition(DEFAULT_MATCHING_THRESHOLD_COLLECTION_DEFINITION_ID, { $expand: 'attributes' }).then(({ data: collectionDefinition }) => {
      getDynamicTableRows(DEFAULT_MATCHING_THRESHOLD_COLLECTION_DEFINITION_ID).then(({ data: dynamicObjs }) => {
        const dynamicData = getDynamicData(dynamicObjs[0], collectionDefinition.attributes);
        setDefaultMatchingThreshold(dynamicData.default_matching_threshold);
      });
    });
  });

  return (
    <MKBox {...props}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleChange, setFieldValue, handleBlur, handleSubmit, errors, values, isSubmitting, dirty }) => {
          return (
            <MKBox component="form" role="form" onSubmit={handleSubmit}>
              <MKBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="text"
                  size="medium"
                  fontSize="16px"
                  borderRadius={backButtonProps?.borderRadius || '0px'}
                  onClick={onClickCancel}
                  iconOnly={false}
                  disabled={false}
                  fullWidth
                  circular={false}
                  color={backButtonProps?.color || 'primary'}
                  {...backButtonProps}
                >
                  {dirty ? lm.cancel_button : lm.back_button }
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  size="medium"
                  fontSize="16px"
                  borderRadius={submitButtonProps?.borderRadius || '0px'}
                  iconOnly={false}
                  disabled={isSubmitting || !dirty}
                  fullWidth
                  circular={false}
                  color={submitButtonProps?.color || 'primary'}
                  {...submitButtonProps}
                >
                  {lm.create_button}
                </Button>
              </MKBox>
              {formAttributes.sort((a1, a2) => a1.sequence - a2.sequence).map((attribute) => {
                if (attribute.operator_field) {
                  return (
                    <MKBox mt={2} key={attribute.attribute_id}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {`${lm.labels_operator}:`}
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {auth.user.display_name || 'Unknown operator'}
                          </MKTypography>
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                if (attribute.date) {
                  return (
                    <MKBox mt={2}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <InputField
                            name={attribute.attribute_id}
                            label={startCase(attribute.name)}
                            value={values[attribute.attribute_id]}
                            type="datetime"
                            button_text={lm.upload_file_button}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                if (attribute.options) {
                  return (
                    <MKBox mt={2}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <InputField
                            name={attribute.attribute_id}
                            label={startCase(attribute.name)}
                            value={values[attribute.attribute_id]}
                            type="select"
                            options={attribute.options}
                            button_text={lm.upload_file_button}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                if (attribute.file_input) {
                  return (
                    <MKBox mt={2}>
                      {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={10}>
                          <ReportFileUploader
                            name={attribute.attribute_id}
                            value={values[attribute.attribute_id]}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            accept_file_types={['audio/*']}
                          />
                        </Grid>
                      </Grid>
                      )}
                    </MKBox>
                  );
                }
                return (
                  <MKBox mt={2}>
                    {!!attribute && (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={3} xl={2}>
                          <MKTypography
                            variant="body1"
                            color="black"
                            fontSize="16px"
                          >
                            {
                              `${startCase(attribute.name)}:`
                            }
                          </MKTypography>
                        </Grid>
                        <Grid
                          xs={attribute.name === 'matching_threshold' ? 11 : 12}
                          md={attribute.name === 'matching_threshold' ? 8 : 9}
                          xl={attribute.name === 'matching_threshold' ? 9 : 10}
                          flexDirection="row"
                        >
                          <InputField
                            name={attribute.attribute_id}
                            label={startCase(attribute.name)}
                            value={values[attribute.attribute_id]}
                            type="text"
                            button_text={lm.upload_file_button}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        </Grid>
                        {attribute.name === 'matching_threshold' && (
                          <Grid item xs={1} md={1} xl={1} paddingLeft="10px">
                            %
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </MKBox>
                );
              })}
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={6} xl={4}>
                  <MKTypography variant="caption" color="error">
                    {errors.form}
                        &nbsp;
                  </MKTypography>
                </Grid>
              </Grid>
            </MKBox>
          );
        }}
      </Formik>
    </MKBox>
  );
};

CollectionCreator.propTypes = {
  formAttributes: PropTypes.array,
  onSubmit: PropTypes.func,
  navigateTo: PropTypes.string,
  backButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
};

export default CollectionCreator;
